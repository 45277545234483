import { MessageProxyBase, PostedMessage, PostedMessageWithType } from '@hiit/shared/message'
import { AuthStorage } from './auth-storage'

export class AuthContentScriptProxy extends MessageProxyBase<'Auth'> {
  _synced: Promise<unknown> | null = null

  init() {
    this.addEventListener('Auth', (event, data) => this.handleAuthMessage(event, data))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAuthMessage(event: MessageEvent<any>, data: PostedMessage<'Auth'>) {
    const returnErrorMessage = (err: Error) => {
      this.returnPostMessage(event, data.messageId, undefined, err)
    }
    switch (data.type) {
      case 'getAll':
        return this.getAll(event, data).catch((err) => returnErrorMessage(err))
      default:
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAll(event: MessageEvent<any>, message: PostedMessageWithType<'Auth', 'getAll'>) {
    const value = await AuthStorage.getAll()
    this.returnPostMessage(event, message.messageId, value)
  }
}
