import secondsToMinutes from 'date-fns/secondsToMinutes'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useRecoilValue } from 'recoil'
import { APP_TITLE } from '../../../consts/envs'
import { usePlatform } from '../../../hooks/usePlatform'
import { timerTickInfoSelector } from '../../../recoil/timer'
import { padLeftStrng } from '../../../utils/util'

export type TimerActionHeadProps = {
  title?: string
}

export const TimerActionHead = ({ title }: TimerActionHeadProps) => {
  const isNative = usePlatform('native')
  const info = useRecoilValue(timerTickInfoSelector)
  const nextSecs = info?.nextSeconds || 0
  const minutes = secondsToMinutes(nextSecs)
  const seconds = nextSecs - minutes * 60

  if (isNative) {
    return null
  }

  return (
    <Fragment key={nextSecs}>
      <Helmet defer={false} titleTemplate={`%s - ${APP_TITLE}`} defaultTitle={APP_TITLE}>
        {title ? (
          <title>{title}</title>
        ) : (
          info &&
          !info.is_finished && (
            <title>
              {{ working: '🚀', interval: '⏳', rest: '🏖️' }[info.interval]}{' '}
              {`${padLeftStrng(minutes)}:${padLeftStrng(seconds)}`}
            </title>
          )
        )}
      </Helmet>
    </Fragment>
  )
}
