import { IonRouterLink } from '@ionic/react'
import clsx from 'clsx'
import { useTheme } from '../hooks/useTheme'

export type CookiePolicyProps = {
  //
}

export const CookiePolicy = (props: CookiePolicyProps) => {
  const { systemTheme } = useTheme()
  return (
    <div className="flex justify-center my-4">
      <article className={clsx('prose md:prose-lg lg:prose-xl', systemTheme === 'dark' && 'dark:prose-invert')}>
        <h1>Cookie policy</h1>
        <div>
          <p>
            This cookie policy (“Policy”) describes what cookies are and how and they’re being used by the{' '}
            <a href="https://unifocus.link" target="_blank" rel="nofollow noreferrer noopener external">
              unifocus.link
            </a>{' '}
            website (“Website” or “Service”) and any of its related products and services (collectively, “Services”).
            This Policy is a legally binding agreement between you (“User”, “you” or “your”) and this Website operator
            (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other
            legal entity, you represent that you have the authority to bind such entity to this agreement, in which case
            the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you
            do not agree with the terms of this agreement, you must not accept this agreement and may not access and use
            the Website and Services. You should read this Policy so you can understand the types of cookies we use, the
            information we collect using cookies and how that information is used. It also describes the choices
            available to you regarding accepting or declining the use of cookies.
          </p>
        </div>
        <div>
          <h3>Table of contents</h3>
          <ol>
            <li>
              <IonRouterLink routerLink="#what-are-cookies">What are cookies?</IonRouterLink>
            </li>
            <li>
              <IonRouterLink routerLink="#what-type-of-cookies-do-we-use">
                What type of cookies do we use?
              </IonRouterLink>
            </li>
            <li>
              <IonRouterLink routerLink="#what-are-your-cookie-options">What are your cookie options?</IonRouterLink>
            </li>
            <li>
              <IonRouterLink routerLink="#changes-and-amendments">Changes and amendments</IonRouterLink>
            </li>
            <li>
              <IonRouterLink routerLink="#acceptance-of-this-policy">Acceptance of this policy</IonRouterLink>
            </li>
            <li>
              <IonRouterLink routerLink="#contacting-us">Contacting us</IonRouterLink>
            </li>
          </ol>
        </div>
        <h2 id="what-are-cookies">What are cookies?</h2>
        <div>
          <p>
            Cookies are small pieces of data stored in text files that are saved on your computer or other devices when
            websites are loaded in a browser. They are widely used to remember you and your preferences, either for a
            single visit (through a “session cookie”) or for multiple repeat visits (using a “persistent cookie”).
          </p>
          <p>
            Session cookies are temporary cookies that are used during the course of your visit to the Website, and they
            expire when you close the web browser.
          </p>
          <p>
            Persistent cookies are used to remember your preferences within our Website and remain on your desktop or
            mobile device even after you close your browser or restart your computer. They ensure a consistent and
            efficient experience for you while visiting the Website and Services.
          </p>
          <p>
            Cookies may be set by the Website (“first-party cookies”), or by third parties, such as those who serve
            content or provide advertising or analytics services on the Website (“third party cookies”). These third
            parties can recognize you when you visit our website and also when you visit certain other websites.
          </p>
        </div>
        <h2 id="what-type-of-cookies-do-we-use">What type of cookies do we use?</h2>
        <div>
          <h3>Necessary cookies</h3>
          <p>
            Necessary cookies allow us to offer you the best possible experience when accessing and navigating through
            our Website and using its features. For example, these cookies let us recognize that you have created an
            account and have logged into that account to access the content.
          </p>
        </div>
        <h2 id="what-are-your-cookie-options">What are your cookie options?</h2>
        <div>
          <p>
            If you don’t like the idea of cookies or certain types of cookies, you can change your browser’s settings to
            delete cookies that have already been set and to not accept new cookies. Visit{' '}
            <a href="https://www.internetcookies.com" target="_blank" rel="noreferrer">
              internetcookies.com
            </a>{' '}
            to learn more about how to do this.
          </p>
          <h2 id="changes-and-amendments">Changes and amendments</h2>
          <p>
            We reserve the right to modify this Policy or its terms related to the Website and Services at any time at
            our discretion. When we do, we will revise the updated date at the bottom of this page, send you an email to
            notify you. We may also provide notice to you in other ways at our discretion, such as through the contact
            information you have provided.
          </p>
          <p>
            An updated version of this Policy will be effective immediately upon the posting of the revised Policy
            unless otherwise specified. Your continued use of the Website and Services after the effective date of the
            revised Policy (or such other act specified at that time) will constitute your consent to those changes.
          </p>
        </div>
        <h2 id="acceptance-of-this-policy">Acceptance of this policy</h2>
        <div>
          <p>
            You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and
            using the Website and Services you agree to be bound by this Policy. If you do not agree to abide by the
            terms of this Policy, you are not authorized to access or use the Website and Services. This cookie policy
            was created with the help of{' '}
            <a href="https://www.websitepolicies.com" target="_blank" rel="nofollow noreferrer">
              WebsitePolicies
            </a>
            .
          </p>
        </div>
        <h2 id="contacting-us">Contacting us</h2>
        <div>
          <p>
            If you have any questions, concerns, or complaints regarding this Policy or the use of cookies, we encourage
            you to contact us using the details below:
          </p>
          <p>
            <a href="mailto:support@unifocus.link">support@unifocus.link</a>
          </p>
          <p>This document was last updated on October 28, 2022</p>
        </div>
      </article>
    </div>
  )
}
