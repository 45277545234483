import { IonicSafeString, IonToast } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

const CookiePolicyPath = '/policy/cookie-policy'
const ConsentKey = 'cookie-consent'
const ConsentValue = 'done'

export type CookieConsentBannerProps = {
  //
}

export const CookieConsentBanner = (props: CookieConsentBannerProps) => {
  const [value, updateValue] = useLocalStorage<string | null>(ConsentKey, null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (value !== ConsentValue) {
      setShow(true)
    }
  }, [value])

  const okHandler = useCallback(() => {
    setShow(false)
    updateValue(ConsentValue)
  }, [updateValue])

  return (
    <IonToast
      isOpen={show}
      color="medium"
      position="bottom"
      message={
        new IonicSafeString(
          `This website uses cookies to ensure you get the best experience on our website. <ion-router-link href="${CookiePolicyPath}">Learn more</ion-router-link>`
        )
      }
      buttons={[
        {
          text: 'Ok',
          role: 'cancel',
          handler: okHandler,
        },
      ]}
    />
  )
}
