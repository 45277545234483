import { useAudio } from '../../hooks/useAudio'
import { useMeTimezone } from '../../hooks/useMe'
import { usePlatform } from '../../hooks/usePlatform'
import { useTimerAutomation, useTimerSyncAutomation } from '../../hooks/useTimer'
import { useTimeConfigAutomation } from '../../hooks/useTimerConfig'
import { ContinueTimerActionSheet } from './action/ContinueTimerActionSheet'
import { CookieConsentBanner } from './utils/CookieConsentBannar'

export const AppModule = () => {
  const isNative = usePlatform('native')
  useTimeConfigAutomation()
  useTimerAutomation()
  useTimerSyncAutomation()
  useMeTimezone()
  const { props: bellProps } = useAudio('bell')
  const { props: tickProps } = useAudio('tick')

  return (
    <>
      <audio {...bellProps} />
      <audio {...tickProps} />
      <ContinueTimerActionSheet />
      {isNative ? null : <CookieConsentBanner />}
    </>
  )
}
