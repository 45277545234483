import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { DEFAULT_TIMER_CONFIG } from '../consts/timer'
import { authState } from '../recoil/auth'
import { timerConfigSelector, timerConfigState } from '../recoil/timer'
import { localTimerActions, syncTimerActions, timerActions } from '../recoil/timerActions'

export const useTimeConfig = () => {
  const timerConfig = useRecoilValue(timerConfigSelector) ?? DEFAULT_TIMER_CONFIG
  const { saveConfig } = useRecoilValue(timerActions)

  return {
    timerConfig,
    saveConfig,
  }
}

export const useTimeConfigAutomation = () => {
  const auth = useRecoilValue(authState)

  const localConfig = useRecoilValue(timerConfigState('local'))
  const syncConfig = useRecoilValue(timerConfigState('sync'))
  const localActions = useRecoilValue(localTimerActions)
  const timerActions = useRecoilValue(syncTimerActions)

  useEffect(() => {
    if (!auth && !localConfig) {
      localActions.saveConfig(DEFAULT_TIMER_CONFIG)
    }
  }, [auth, localActions, localConfig])

  useEffect(() => {
    if (!auth || !timerActions) {
      return
    }

    if (!syncConfig) {
      const { id, ...config } = localConfig ?? DEFAULT_TIMER_CONFIG
      timerActions.saveConfig(config)
    }
  }, [auth, localConfig, syncConfig, timerActions])
}
