import { Canvg } from 'canvg'
import { ReactNode, useCallback, useId, useRef, useState } from 'react'
import { PictureInPictureAction } from './action/PictureInPictureAction'

// @ts-ignore
const detectAppleWebkit = () => typeof window?.webkitConvertPointFromNodeToPage === 'function'

export type PictureInPictureProps = {
  children: ReactNode
}

export const PictureInPicture = ({ children }: PictureInPictureProps) => {
  const divId = useId()
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)
  const [nowPlay, setNowPlay] = useState(false)
  const isAppleWebkit = detectAppleWebkit()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const intervalRef = useRef(NaN)

  const clickHandler = useCallback(async () => {
    const video = videoRef
    const canvas = canvasRef.current
    if (!video || !canvas) {
      return
    }
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }
    const playSvg = async () => {
      const svg = document.getElementById('svg-id') as unknown as SVGElement
      const v = await Canvg.fromString(ctx, svg.innerHTML as string)
      v.render()
    }
    const stream = canvas.captureStream(5000)
    video.srcObject = stream
    // autoPictureInPicture -> https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/autoPictureInPicture
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-extra-semi
    ;(video as any).autoPictureInPicture = true
    video.play()
    await playSvg()
    window.clearInterval(intervalRef.current)
    intervalRef.current = window.setInterval(playSvg, 500)

    let retryCount = 0
    const enterPipHandler = () => {
      video.removeEventListener('enterpictureinpicture', enterPipHandler)
    }
    const leavePipHandler = () => {
      setNowPlay(false)
      window.clearInterval(intervalRef.current)
      video.pause()
      video.removeEventListener('leavepictureinpicture', leavePipHandler)
    }
    video.addEventListener('enterpictureinpicture', enterPipHandler)
    video.addEventListener('leavepictureinpicture', leavePipHandler)
    const enterPIP = async () => {
      try {
        setNowPlay(true)
        await video.requestPictureInPicture()
      } catch (err) {
        console.warn(err)
        if (retryCount > 5) {
          setNowPlay(false)
          video.removeEventListener('leavepictureinpicture', leavePipHandler)
          video.removeEventListener('enterpictureinpicture', enterPipHandler)
          console.error(err)
          return
        }
        retryCount += 1
        window.setTimeout(enterPIP, 300)
      }
    }
    enterPIP()
  }, [videoRef])

  return (
    <>
      <video ref={(ref) => setVideoRef(ref)} autoPlay hidden={!isAppleWebkit || !nowPlay} playsInline></video>
      <canvas ref={canvasRef} width={600} height={600} hidden></canvas>
      <div id={divId} hidden={isAppleWebkit && nowPlay}>
        {children}
      </div>
      {videoRef?.requestPictureInPicture && <PictureInPictureAction active={true} onClick={clickHandler} />}
    </>
  )
}
