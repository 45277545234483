import { Auth } from 'aws-amplify'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { userActions } from '../recoil/user'
import { useStorage } from './useStorage'

export const useAccountDelete = () => {
  const { deleteUser } = useRecoilValue(userActions)
  const { clearStorage } = useStorage()

  const accountDelete = useCallback(async () => {
    await clearStorage()
    await deleteUser()
    await Auth.deleteUser()
  }, [clearStorage, deleteUser])

  return { accountDelete }
}
