import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonToast,
} from '@ionic/react'
import { Hub } from 'aws-amplify'
import { logOutOutline, trashOutline } from 'ionicons/icons'
import { FC, ReactNode, Suspense, useCallback, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { APP_TITLE } from '../../consts/envs'
import { DISCORD_LINK } from '../../consts/links'
import { useAccountDelete } from '../../hooks/useAccount'
import { useStorage } from '../../hooks/useStorage'
import { logger } from '../../utils/logger'
import { DiscordLogo } from '../atoms/svg/DiscordLogo'
import { AuthHeaderItem } from '../organisms/action/AuthHeaderItem'
import { TimerActionHead } from '../organisms/head/TimerActionHead'
import { ThemeSelector } from '../organisms/header/ThemeSelector'

// Copy from node_modules/.pnpm/@aws-amplify+core@4.7.11/node_modules/@aws-amplify/core/lib-esm/Hub.d.ts
type HubCapsule = {
  channel: string
  payload: HubPayload
  source: string
  patternInfo?: string[]
}
type HubPayload = {
  event: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  message?: string
}

export interface MainTemplateProps {
  title?: string
  children?: ReactNode
}

export const MainTemplate: FC<MainTemplateProps> = ({ title, children }) => {
  const { clearStorage } = useStorage()
  const { signOut } = useAuthenticator((context) => [context.user])
  const { accountDelete } = useAccountDelete()
  const [toastPresent] = useIonToast()
  const [sheetPresent] = useIonActionSheet()

  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  const signOutReload = useCallback((data: HubCapsule) => {
    switch (data.payload.event) {
      case 'signOut':
        logger.info('user signed out')
        window.location.reload()
        break
      default:
        logger.error('Event name', data.payload.event)
        break
    }
  }, [])
  const handleLogout = useCallback(async () => {
    await clearStorage()
    signOut()
    Hub.listen('auth', signOutReload)
  }, [clearStorage, signOut, signOutReload])

  const handleDeleteAccountClick = useCallback(() => {
    sheetPresent({
      header: 'Delete Account',
      subHeader: 'Are you sure to delete your account?\nAll data will be deleted.\nThis cannot be undone.',
      backdropDismiss: true,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          handler: async () => {
            try {
              await accountDelete()
              toastPresent({
                message: 'Account Deleted',
                duration: 3000,
                position: 'bottom',
                color: 'medium',
              })
            } catch (err) {
              if (err instanceof Error) {
                toastPresent({
                  message: err?.message,
                  duration: 3000,
                  position: 'bottom',
                  color: 'danger',
                })
              }
            }
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    })
  }, [accountDelete, sheetPresent, toastPresent])

  useEffect(() => {
    Hub.remove('auth', signOutReload)
  }, [signOutReload])

  return (
    <>
      <Suspense>
        <TimerActionHead title={title} />
      </Suspense>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonRouterLink routerLink="/app" color="dark">
              <IonTitle className="font-logo">{APP_TITLE}</IonTitle>
            </IonRouterLink>
            <IonButtons slot="start">
              <IonMenuButton auto-hide="false" menu="main-menu"></IonMenuButton>
            </IonButtons>
            <IonButtons slot="primary" color="none">
              <Suspense fallback={<></>}>
                <ThemeSelector />
                <AuthHeaderItem menuId="user-menu" />
              </Suspense>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent id="main-content">
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <ErrorBoundary fallback={<>Error</>}>
            <Suspense>{children}</Suspense>
          </ErrorBoundary>
        </IonContent>

        <IonMenu side="start" type="overlay" contentId="main-content" menuId="main-menu">
          <IonHeader>
            <IonToolbar color="secondary">
              <IonTitle>Menu</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem lines="none" href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
                <DiscordLogo className="w-7 h-7" />
                <IonLabel className="ml-2">Discord</IonLabel>
              </IonItem>
              <IonRouterLink routerLink="/policy/privacy-policy">
                <IonItem lines="none">
                  <IonLabel className="ml-2">Privacy Policy</IonLabel>
                </IonItem>
              </IonRouterLink>
              <IonRouterLink routerLink="/policy/cookie-policy">
                <IonItem lines="none">
                  <IonLabel className="ml-2">Cookie Policy</IonLabel>
                </IonItem>
              </IonRouterLink>
            </IonList>
          </IonContent>
        </IonMenu>

        <IonMenu side="end" type="overlay" contentId="main-content" menuId="user-menu">
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>User</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="flex flex-col h-full">
              <IonList>
                <IonItem button onClick={handleLogout} lines="none">
                  <IonIcon icon={logOutOutline} />
                  <IonLabel className="ml-2">Logout</IonLabel>
                </IonItem>
              </IonList>
              <div className="my-auto" />
              <IonList>
                <IonItem button onClick={handleDeleteAccountClick} lines="none">
                  <IonIcon icon={trashOutline} color="danger" />
                  <IonLabel className="ml-2" color="danger">
                    Delete Account
                  </IonLabel>
                </IonItem>
              </IonList>
            </div>
          </IonContent>
        </IonMenu>
      </IonPage>
    </>
  )
}
