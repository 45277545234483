import type { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import { atom } from 'recoil'
import { logger } from '../utils/logger'

export const authState = atom<CognitoUserSession | undefined>({
  key: 'AuthState',
  default: undefined,
  effects: [
    ({ trigger, setSelf }) => {
      if (trigger === 'set') {
        return
      }
      Auth.currentSession()
        .then((session) => {
          if (session.isValid()) {
            setSelf(session)
          }
        })
        .catch((err) => {
          logger.debug(err)
          setSelf(undefined)
        })
    },
    ({ setSelf }) => {
      Hub.listen('auth', async (data) => {
        console.log('CHECK listen auth', data)
        try {
          const session = await Auth.currentSession()
          setSelf(session.isValid() ? session : undefined)
        } catch (err) {
          console.warn(err)
          setSelf(undefined)
        }
      })
    },
  ],
})
