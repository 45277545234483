import { useId } from 'react'

export type SessionNumberSelectProps = {
  selected: number
  onChange: (selected: number) => void
}

export const SessionNumberSelect = ({ selected, onChange }: SessionNumberSelectProps) => {
  const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const id = useId()
  return (
    <div className="flex gap-2 items-center w-1/2 max-w-md">
      <label htmlFor={id}>Sessions</label>
      <select
        id={id}
        className="block flex-auto py-1 px-2 text-sm font-normal text-gray-700 dark:text-white bg-transparent rounded border border-gray-300 focus:border-blue-600 border-solid focus:outline-none appearance-none"
        aria-label="Session"
        value={selected}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {values.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    </div>
  )
}
