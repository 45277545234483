import { nanoid } from 'nanoid'

abstract class AbstractID {
  public readonly _type!: string
  public readonly id!: string

  public equals(other: AbstractID | undefined): boolean {
    if (!other) {
      return false
    }
    return this._type === other._type && this.id === other.id
  }

  public toJSON() {
    return JSON.stringify({ _type: this._type, id: this.id })
  }
}

export class LocalID extends AbstractID {
  readonly _type = 'local'
  constructor(public readonly id = nanoid()) {
    super()
  }
}

export class SyncID extends AbstractID {
  readonly _type = 'sync'
  constructor(public readonly id: string) {
    super()
  }
}

export interface DeserializedID {
  _type: 'local' | 'sync'
  id: string
}

export const mapID = ({ id, _type }: DeserializedID) => {
  if (_type === 'sync') {
    return new SyncID(id)
  }
  return new LocalID(id)
}
