import { SVGProps } from 'react'

export const AwardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 144 144" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter id="a" x="-.072" y="-.072" width="1.144" height="1.144" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity=".376" floodColor="#000" result="flood" />
          <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1" />
          <feGaussianBlur in="composite1" stdDeviation="3" result="blur" />
          <feOffset result="offset" />
          <feComposite in="SourceGraphic" in2="offset" result="composite2" />
        </filter>
        <path id="b" d="M123.482 127.598h140.463v146.304H123.482z" />
      </defs>
      <path d="M76.982 116.186v19.058l7.026-4.194c.595-.356 1.077-1.205 1.077-1.898V116.186z" fill="#7a9fec" />
      <path d="M67.018 116.186v19.058L72 138.218l4.982-2.974v-19.058z" fill="#ebedf2" />
      <path d="M58.915 116.186V129.161c0 .688.479 1.531 1.07 1.884l7.033 4.2V116.185z" fill="#7ba0ee" />
      <circle cx="72" cy="71.25" r="50" fill="#d1dcf3" filter="url(#a)" />
      <circle cx="72" cy="71.25" r="39" fill="#7ba0ee" />
    </svg>
  )
}
