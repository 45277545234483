"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionFailureErrorCode = exports.DatabaseErrorCode = exports.NoRecordErrorCode = exports.NoUserErrorCode = exports.ApplicationErrorCode = void 0;
// App error
exports.ApplicationErrorCode = 'ApplicationError';
exports.NoUserErrorCode = 'NoUserError';
exports.NoRecordErrorCode = 'NoRecordError';
// Database error
exports.DatabaseErrorCode = 'DatabaseError';
exports.TransactionFailureErrorCode = 'TransactionFailureError';
