import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { IonButton, IonReorderGroup, ItemReorderEventDetail, useIonActionSheet, useIonToast } from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import _random from 'lodash/random'
import { useCallback, useReducer, useState } from 'react'
import { TaskItem } from '../../components/organisms/task/TaskItem'
import { COLORS } from '../../consts/color'
import { useTask } from '../../hooks/useTask'

export const DEFAULT_TASK = {
  task_name: '',
  session_count: 4,
  color_name: 'transparent',
  color_code: 'transparent',
}

type NewTaskAction =
  | {
      type: 'init'
    }
  | {
      type: 'reset'
    }

export const Task = () => {
  const [present] = useIonActionSheet()
  const [toastPresent] = useIonToast()
  const { user, authStatus } = useAuthenticator((context) => [context.user])
  const [editingId, setEditingId] = useState<string>()
  const [newTask, dispatch] = useReducer((state: typeof DEFAULT_TASK | undefined, action: NewTaskAction) => {
    switch (action.type) {
      case 'init': {
        const color = COLORS[_random(0, COLORS.length)]
        return {
          ...DEFAULT_TASK,
          ...(color
            ? {
                color_code: color.code,
                color_name: color.color,
              }
            : {}),
        }
      }
      case 'reset':
        return undefined
      default:
        throw new Error('Unexpected')
    }
  }, undefined)

  const { task, tasks, createTask, deleteTask, updateTask, sortTask } = useTask()

  const deleteItem = useCallback(
    (id: string) => {
      const onDidDismiss = async (detail: OverlayEventDetail) => {
        if (detail.role !== 'destructive') {
          return
        }
        const result = await deleteTask(id)
        if (result?.error) {
          toastPresent({
            message: result?.error?.message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          })
        } else {
          toastPresent({
            message: 'Task Deleted',
            duration: 2000,
            position: 'bottom',
            color: 'medium',
          })
        }
      }
      present({
        header: 'Delete task',
        buttons: [
          {
            text: 'Delete',
            role: 'destructive',
            data: {
              action: 'delete',
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
        onDidDismiss: ({ detail }) => onDidDismiss(detail),
      })
    },
    [deleteTask, present, toastPresent]
  )

  const handleReorder = useCallback(
    async (event: CustomEvent<ItemReorderEventDetail>) => {
      // The `from` and `to` properties contain the index of the item
      // when the drag started and ended, respectively
      console.log('Dragged from index', event.detail.from, 'to', event.detail.to)
      const from = event.detail.from
      const to = event.detail.to

      const result = await sortTask({ from, to })
      if (result?.error) {
        toastPresent({
          message: result?.error?.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
        return
      }
      // Finish the reorder and position the item in the DOM based on
      // where the gesture ended. This method can also be called directly
      // by the reorder group
      event.detail.complete()
    },
    [sortTask, toastPresent]
  )

  if (!user || authStatus !== 'authenticated') {
    return (
      <div className="grid place-content-center w-full h-full">
        <Authenticator loginMechanisms={['email']} socialProviders={['google', 'apple']}></Authenticator>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center h-full">
      <div className="container">
        <IonReorderGroup
          disabled={false}
          onIonItemReorder={handleReorder}
          className="list-none divide-y dark:divide-gray-700"
        >
          {tasks.map(({ id, task_name, session_count, color_name, color_code }) => (
            <TaskItem
              key={id}
              id={id}
              taskName={task_name}
              sessionCount={session_count}
              finishedCount={task ? task.task_finished.filter((fid) => fid === id).length : 0}
              colorName={color_name}
              colorCode={color_code}
              editing={id === editingId}
              onCancelEdit={() => setEditingId(undefined)}
              onStartEdit={(id) => setEditingId(id)}
              onDelete={deleteItem}
              onSave={async (value) => {
                if (!value.id) {
                  return
                }
                const result = await updateTask({
                  id: value.id,
                  task_name: value.taskName,
                  color_name: value.colorName,
                  color_code: value.colorCode,
                  session_count: value.sessionCount,
                })
                if (result?.error) {
                  toastPresent({
                    message: result?.error?.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger',
                  })
                } else {
                  setEditingId(undefined)
                }
              }}
            />
          ))}
          {newTask && (
            <TaskItem
              taskName={newTask.task_name}
              sessionCount={newTask.session_count}
              colorName={newTask.color_name}
              colorCode={newTask.color_code}
              editing
              onCancelEdit={() => dispatch({ type: 'reset' })}
              onSave={async (value) => {
                const result = await createTask({
                  task_name: value.taskName,
                  color_name: value.colorName,
                  color_code: value.colorCode,
                  session_count: value.sessionCount,
                })
                if (result?.error) {
                  toastPresent({
                    message: result?.error?.message,
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger',
                  })
                } else {
                  dispatch({ type: 'reset' })
                }
              }}
            />
          )}
        </IonReorderGroup>
        {!newTask && (
          <IonButton shape="round" expand="full" onClick={() => dispatch({ type: 'init' })}>
            add
          </IonButton>
        )}
      </div>
    </div>
  )
}
