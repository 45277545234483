const clockUtils = {
  getFineMinutes(date: Date) {
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return minutes + seconds / 60
  },
  getFineMinutesWithTimestamp(timestanp: number) {
    return clockUtils.getFineMinutes(new Date(timestanp))
  },
  getTheta(deg: number) {
    return (deg / 360) * (2 * Math.PI)
  },
  getX(deg: number) {
    return Math.cos(clockUtils.getTheta(deg))
  },
  getY(deg: number) {
    return Math.sin(clockUtils.getTheta(deg))
  },
  getXY(deg: number) {
    return {
      x: clockUtils.getX(deg),
      y: clockUtils.getY(deg),
    }
  },
  getTimeDeg60(val: number) {
    return (360 / 60) * val - 90
  },
  getTimeDeg12(val: number) {
    return (360 / 12) * val - 90
  },
  getStroke(width: number, height: number, base = 120) {
    return (width + height / 2) / base
  }
}

export default clockUtils
