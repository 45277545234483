import { IonButton, IonIcon, IonReorder } from '@ionic/react'
import clsx from 'clsx'
import { closeCircleOutline } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { TaskColorRadio } from '../../molecules/radio/TaskColorRadio'
import { SessionNumberSelect } from '../../molecules/select/SessionNumberSelect'

export type TaskItemProps = {
  editing: boolean
  id?: string
  colorName: string
  colorCode: string
  taskName: string
  sessionCount: number
  finishedCount?: number
  onStartEdit?: (id: string) => void
  onCancelEdit: () => void
  onDelete?: (id: string) => void
  onSave: (value: { id?: string; colorName: string; colorCode: string; taskName: string; sessionCount: number }) => void
}

export const TaskItem = ({
  editing,
  id,
  colorName,
  colorCode,
  taskName,
  sessionCount,
  finishedCount,
  onStartEdit,
  onCancelEdit,
  onDelete,
  onSave,
}: TaskItemProps) => {
  const [value, setValue] = useState({
    id,
    colorName,
    colorCode,
    taskName,
    sessionCount,
  })
  const update = useCallback((updated: Partial<typeof value>) => setValue((prev) => ({ ...prev, ...updated })), [])

  useEffect(
    () =>
      update({
        id,
        colorName,
        colorCode,
        taskName,
        sessionCount,
      }),
    [id, colorName, colorCode, taskName, sessionCount, update]
  )

  const cancel = useCallback(() => {
    update({
      id,
      colorName,
      colorCode,
      sessionCount,
      taskName,
    })
    onCancelEdit()
  }, [update, id, colorName, colorCode, sessionCount, taskName, onCancelEdit])

  const save = useCallback(() => {
    onSave(value)
  }, [onSave, value])

  return (
    <li className={'relative text-white'} key={id}>
      <div className="flex gap-2 justify-center items-center p-2">
        <div
          className={clsx('absolute', !editing ? 'inset-0' : 'right-0 bottom-0 w-12 h-12')}
          style={
            !editing
              ? { backgroundColor: value.colorCode, zIndex: -1 }
              : {
                  zIndex: -1,
                  borderWidth: '0 0 3rem 3rem',
                  borderColor: `transparent transparent ${value.colorCode} transparent`,
                }
          }
        ></div>
        <IonReorder className="text-gray-700"></IonReorder>
        {!editing ? (
          <button
            className={clsx(
              'flex flex-col flex-auto items-start mx-1',
              value.colorCode === 'transparent' ? 'text-black dark:text-white' : 'text-white'
            )}
            onClick={id ? () => onStartEdit?.(id) : undefined}
          >
            <label className="text-lg font-bold">{value.taskName}</label>
            <div>
              <label>
                {finishedCount ?? 0}/{value.sessionCount}
              </label>{' '}
              Sessions
            </div>
          </button>
        ) : (
          <div className={clsx('flex flex-col flex-auto gap-1 items-start mx-1 text-black dark:text-white')}>
            <input
              value={value.taskName}
              placeholder="Task name"
              className="px-1 w-full text-lg bg-transparent border-b"
              autoFocus
              onChange={(e) => update({ taskName: e.target.value })}
            />
            <div className="w-full">
              <SessionNumberSelect
                selected={value.sessionCount}
                onChange={(count) => update({ sessionCount: count })}
              />
            </div>
            <div className="my-1">
              <TaskColorRadio
                selected={value.colorName}
                onChange={({ colorName, colorCode }) => update({ colorCode, colorName })}
              />
            </div>
            <div className="grid grid-cols-2 w-full">
              <IonButton size="small" expand="block" color="tertiary" fill="outline" onClick={cancel}>
                cancel
              </IonButton>
              <IonButton size="small" expand="block" onClick={save}>
                Save
              </IonButton>
            </div>
          </div>
        )}
        {!editing && id && (
          <div>
            <IonButton
              fill="clear"
              size="small"
              className={clsx('inline-flex', value.colorCode === 'transparent' ? 'text-red-500' : 'text-white')}
              onClick={() => onDelete?.(id)}
            >
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </div>
        )}
      </div>
    </li>
  )
}
