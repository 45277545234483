import { addMinutes } from 'date-fns'
import addSeconds from 'date-fns/addSeconds'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds'
import set from 'date-fns/set'
import _uniq from 'lodash/uniq'
import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil'
import { DEFAULT_TIMER_CONFIG, TICK_INTERVAL } from '../consts/timer'
import { DeserializedID, LocalID, mapID, SyncID } from '../entity/ID'
import { storageEffect } from '../helpers/effectHelper'
import { StorageEnum } from '../storage/default'
import { historyStorage } from '../storage/history'
import { isoDateFormat } from '../utils/format'
import { authedClientSelector } from './client'

const ONE_MINUTES_MS = 60 * 1000

export type TimerState = {
  id: LocalID | SyncID
  start_time: number
  end_time: number
  reps: number
  config_id: LocalID | SyncID
  prev_id?: LocalID | SyncID | undefined
  is_finished: boolean
  is_valid: boolean
  task?: {
    id: string
    task_name: string
    color_name: string
    color_code: string
    session_count: number
  }
}

export type DeserializedTimerState = Omit<TimerState, 'id' | 'config_id' | 'prev_id'> & {
  id: DeserializedID
  config_id: DeserializedID
  prev_id?: DeserializedID
}

export const timerMappter = ({
  id,
  start_time,
  end_time,
  reps,
  config_id,
  prev_id,
  is_finished,
  is_valid,
  task,
}: DeserializedTimerState): TimerState => ({
  id: mapID(id),
  config_id: mapID(config_id),
  prev_id: prev_id ? mapID(prev_id) : undefined,
  reps,
  start_time,
  end_time,
  is_finished,
  is_valid,
  task,
})

export const timerState = atom<TimerState | null | undefined>({
  key: 'Timer/timerState',
  default: undefined,
  effects: [
    storageEffect<TimerState | null | undefined, DeserializedTimerState>(
      StorageEnum.TIMER,
      (store: DeserializedTimerState) => (store && store.id ? timerMappter(store) : null)
    ),
    ({ onSet }) => {
      onSet(async (value, prev, isReset) => {
        const latestKey = 'latest'
        if (!value || value instanceof DefaultValue) {
          if (!prev || prev instanceof DefaultValue) {
            return
          }
          const type = prev.id._type
          const id = prev.id.id
          await historyStorage.remove(latestKey)
          const dateKey = isoDateFormat(prev.start_time)
          const keysIndex = `${type}:${dateKey}`
          const keys: string[] = (await historyStorage.get(keysIndex)) || []
          await historyStorage.set(
            keysIndex,
            keys.filter((key) => key !== id)
          )
          const itemIndex = `${type}:${id}`
          await historyStorage.remove(itemIndex)
        } else {
          const latest = value as TimerState
          const type = latest.id._type
          const latestId = latest.id.id
          historyStorage.set(latestKey, latest)

          const dateKey = isoDateFormat(latest.start_time)
          const keysIndex = `${type}:${dateKey}`
          const keys: string[] = (await historyStorage.get(keysIndex)) || []
          const uqKeys = _uniq([...keys, latestId])
          await historyStorage.set(keysIndex, uqKeys)

          const newIndex = `${type}:${latestId}`
          await historyStorage.set(newIndex, latest)
          if (prev && !(prev instanceof DefaultValue) && prev.id.id !== latest.id.id) {
            // simulate is_finished
            const oldIndex = `${type}:${prev.id.id}`
            await historyStorage.set(oldIndex, {
              ...prev,
              is_finished: true,
              is_valid: true,
            })
          }
        }
      })
    },
  ],
})

export const tickState = atom({
  key: 'TimerState/tickState',
  default: Date.now(),
  effects: [
    ({ setSelf }) => {
      const id = window.setInterval(() => {
        setSelf(Date.now())
      }, TICK_INTERVAL)
      return () => {
        window.clearInterval(id)
      }
    },
  ],
})

export const tickSelector = selectorFamily<number, 'seconds' | 'minutes' | 'hours'>({
  key: 'TimerState/tickSelector',
  get:
    (type) =>
    ({ get }) => {
      const tick = get(tickState)
      let time: number = tick
      if (type === 'seconds') {
        const _base = set(tick, { milliseconds: 0 }).getTime()
        const oneSecAhead = addSeconds(_base, 1).getTime()
        time = differenceInMilliseconds(tick, oneSecAhead) < TICK_INTERVAL / 3 ? oneSecAhead : _base
      } else if (type === 'minutes') {
        time = set(tick, { milliseconds: 0, seconds: 0 }).getTime()
      } else if (type === 'hours') {
        time = set(tick, { milliseconds: 0, seconds: 0, minutes: 0 }).getTime()
      }
      return time
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const timerActiveSelector = selector<TimerState | undefined>({
  key: 'Timer/timerActiveSelector',
  get: ({ get }) => {
    const config = get(timerConfigSelector)
    const timer = get(timerState)
    if (!timer || !config) {
      return undefined
    }
    if (!timer.config_id.equals(config.id)) {
      return undefined
    }
    const tick = get(tickState)
    if (tick > timer.end_time) {
      return undefined
    }
    return timer
  },
})

export const timerGaugeSelector = selector({
  key: 'Timer/timerGaugeSelector',
  get: ({ get }) => {
    const config = get(timerConfigSelector)
    const timer = get(timerState)
    if (!timer || !config) {
      return undefined
    }
    if (!timer.config_id.equals(config.id) || timer.is_finished) {
      return undefined
    }
    const isRest = timer.reps === config.reps
    const tick = get(tickSelector('seconds'))
    if (tick > timer.end_time) {
      const minutes = isRest ? config.rest_minutes : config.interval_minutes
      return {
        interval: timer.reps === config.reps ? 'rest' : 'interval',
        from_time: tick,
        to_time: addMinutes(timer.end_time, minutes).getTime(),
      }
    }
    if (tick < timer.start_time) {
      return {
        interval: isRest ? 'rest' : 'interval',
        from_time: tick,
        to_time: timer.start_time,
      }
    }
    return {
      interval: 'working',
      from_time: timer.start_time,
      to_time: timer.end_time,
    }
  },
})

export type TimerConfig = {
  id: LocalID | SyncID
  focus_minutes: number
  interval_minutes: number
  reps: number
  rest_minutes: number
}

export type DeserializedTimerConfigState = Omit<TimerConfig, 'id'> & {
  id: DeserializedID
}

const timerConfigMappter = ({
  id,
  focus_minutes,
  interval_minutes,
  reps,
  rest_minutes,
}: DeserializedTimerConfigState): TimerConfig => ({
  id: mapID(id),
  focus_minutes,
  interval_minutes,
  reps,
  rest_minutes,
})

export const timerConfigState = atomFamily<TimerConfig | null, 'local' | 'sync'>({
  key: 'Timer/timerConfigSyncState',
  default: DEFAULT_TIMER_CONFIG,
  effects: (key) => [
    storageEffect<TimerConfig | null, DeserializedTimerConfigState>(`${StorageEnum.TIMER_CONFIG}-${key}`, (store) =>
      store && store.id ? timerConfigMappter(store) : null
    ),
  ],
})

export const timerConfigSelector = selector<TimerConfig | null>({
  key: 'Timer/timerConfigSelector',
  get: ({ get }) => {
    const client = get(authedClientSelector)

    return get(timerConfigState(client ? 'sync' : 'local'))
  },
})

export const timerRetryCountState = atom({
  key: 'Timer/timerRetryCountState',
  default: 0,
})

export const timerTickInfoSelector = selector<
  | undefined
  | {
      info: 'not-started' | 'working' | 'completed'
      interval: 'working' | 'rest' | 'interval'
      nextSeconds: number
      is_finished: boolean
      timer: TimerState
    }
>({
  key: 'Timer/timerTickInfoSelector',
  get: ({ get }) => {
    const tickTime = get(tickSelector('seconds'))
    const timer = get(timerState)
    const config = get(timerConfigSelector)

    if (!timer || !config) {
      return undefined
    }

    const isRest = timer.reps === config.reps
    const is_finished = Boolean(timer.is_finished)
    if (tickTime < timer.start_time) {
      return {
        info: 'not-started',
        interval: isRest ? 'rest' : 'interval',
        nextSeconds: millisecondsToSeconds(timer.start_time - tickTime),
        is_finished,
        timer,
      }
    } else if (tickTime < timer.end_time) {
      return {
        info: 'working',
        interval: 'working',
        nextSeconds: millisecondsToSeconds(timer.end_time - tickTime),
        is_finished,
        timer,
      }
    } else {
      return {
        info: 'completed',
        interval: isRest ? 'rest' : 'interval',
        nextSeconds: millisecondsToSeconds(
          timer.end_time + config[isRest ? 'rest_minutes' : 'interval_minutes'] * ONE_MINUTES_MS - tickTime
        ),
        is_finished,
        timer,
      }
    }
  },
})
