import { selector } from 'recoil'
import { getClient } from '../utils/urql'
import { authState } from './auth'

export const urqlClient = selector({
  key: 'Client/urqlClient',
  get: () => getClient(),
  dangerouslyAllowMutability: true,
})

export const authedClientSelector = selector({
  key: 'Client/authedClientSelector',
  get: ({ get }) => {
    const auth = get(authState)
    if (!auth) {
      return undefined
    }
    return get(urqlClient)
  },
  dangerouslyAllowMutability: true,
})
