import { Authenticator } from '@aws-amplify/ui-react'
import { IonButton, IonItem, IonLoading } from '@ionic/react'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import { PageTemplate } from '../components/templates/PageTemplate'
import { logger } from '../utils/logger'

const Login: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location.search.indexOf('code=') >= 0) {
      setLoading(true)
      Auth.currentSession()
        .then(() => {
          history.replace('/login')
        })
        .catch((err) => {
          logger.info('Not auth, reloading...', err)
          window.location.reload()
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [history, location])

  return (
    <PageTemplate title="Login">
      <div className="flex justify-center items-center h-full">
        <IonLoading cssClass="my-custom-class" isOpen={loading} message={'Please wait...'} />
        <Authenticator loginMechanisms={['email']} socialProviders={['google', 'apple']}>
          {({ signOut, user }) =>
            loading ? (
              <IonItem lines="none" className="mt-4">
                <IonButton color="primary" size="default" type="submit" onClick={() => signOut?.(user)}>
                  Logout
                </IonButton>
              </IonItem>
            ) : (
              <Redirect to="/app" />
            )
          }
        </Authenticator>
      </div>
    </PageTemplate>
  )
}

export default Login
