import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPopover } from '@ionic/react'
import { contrastOutline, moonOutline, sunnyOutline } from 'ionicons/icons'
import { FC, useId } from 'react'

type ThemeOptions = 'light' | 'dark'

export interface ThemeToggleProps {
  theme?: ThemeOptions
  onChange?: (theme?: ThemeOptions) => void
}

export const ThemeToggle: FC<ThemeToggleProps> = ({ theme, onChange }) => {
  const id = useId()
  const currentThemeIcon = {
    light: sunnyOutline,
    dark: moonOutline,
    undefined: contrastOutline,
  }[`${theme}`]

  return (
    <>
      <IonButton id={id} color="secondary" fill="clear">
        <IonIcon icon={currentThemeIcon} size="small" slot="icon-only" />
      </IonButton>
      <IonPopover trigger={id} dismissOnSelect={true} showBackdrop={true} arrow alignment="center" className="text-sm">
        <IonList lines="none">
          <IonItem button={true} onClick={onChange?.bind(null, 'light')}>
            <IonLabel>
              <IonIcon icon={sunnyOutline} className="align-middle" />
              <IonNote className="ml-2">Light</IonNote>
            </IonLabel>
          </IonItem>
          <IonItem button={true} onClick={onChange?.bind(null, 'dark')}>
            <IonLabel>
              <IonIcon icon={moonOutline} class="align-middle" />
              <IonNote className="ml-2">Dark</IonNote>
            </IonLabel>
          </IonItem>
          <IonItem button={true} onClick={onChange?.bind(null, undefined)}>
            <IonLabel>
              <IonIcon icon={contrastOutline} class="align-middle" />
              <IonNote className="ml-2">System</IonNote>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  )
}

export default ThemeToggle
