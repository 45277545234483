import { FC, useCallback, useMemo } from 'react'
import clockUtils from '../../../../utils/clock-utils'
import type { TimerViewport } from '../../../../viewport'

export interface NormalGaugeProps {
  viewport: TimerViewport
  radius: number
  start: number
  end: number
}

const multiplyXY = (obj: { x: number; y: number }, times: number) => {
  return {
    x: obj.x * times,
    y: obj.y * times,
  }
}

const RADIUS_RATIO = 0.75

export const NormalGauge: FC<NormalGaugeProps> = ({ viewport, radius, start, end }) => {
  const center = viewport.center
  const makeDPath = useCallback(
    (center: { x: number; y: number }, start: number, end: number) => {
      const startMinutes = clockUtils.getFineMinutesWithTimestamp(start)
      const innerCurStart = clockUtils.getXY(clockUtils.getTimeDeg60(startMinutes))

      const curMinutes = clockUtils.getFineMinutesWithTimestamp(end)
      const innerCurEnd = clockUtils.getXY(clockUtils.getTimeDeg60(curMinutes))

      const curStart = multiplyXY(innerCurStart, radius)
      const inCursorStart = multiplyXY(innerCurStart, radius * RADIUS_RATIO)
      const inCursorEnd = multiplyXY(innerCurEnd, radius * RADIUS_RATIO)
      let d = `M${center.x + inCursorStart.x} ${center.y + inCursorStart.y} L ${center.x + curStart.x} ${
        center.y + curStart.y
      }`

      const splitMinutes = 12 * 60_000
      let cursorStart = start
      for (let t = end - start; t >= 0; t -= splitMinutes) {
        const each = t < splitMinutes ? t : splitMinutes
        cursorStart += each
        const minutes = clockUtils.getFineMinutesWithTimestamp(cursorStart)
        const innerCursor = clockUtils.getXY(clockUtils.getTimeDeg60(minutes))
        const cursor = multiplyXY(innerCursor, radius)
        d += ` A ${viewport.center.x} ${viewport.center.y} 0 0 1 ${center.x + cursor.x} ${center.y + cursor.y}`
      }
      d += ` L ${center.x + inCursorEnd.x} ${center.y + inCursorEnd.y}`
      cursorStart = end
      for (let t = end - start; t >= 0; t -= splitMinutes) {
        const each = t < splitMinutes ? t : splitMinutes
        cursorStart -= each
        const minutes = clockUtils.getFineMinutesWithTimestamp(cursorStart)
        const innerCursor = clockUtils.getXY(clockUtils.getTimeDeg60(minutes))
        const cursor = multiplyXY(innerCursor, radius * RADIUS_RATIO)
        d += ` A ${viewport.center.x * RADIUS_RATIO} ${viewport.center.y * RADIUS_RATIO} 0 0 0 ${center.x + cursor.x} ${
          center.y + cursor.y
        }`
      }
      return d
    },
    [radius, viewport]
  )

  const curD = useMemo(() => {
    return makeDPath(center, start, end)
  }, [center, end, makeDPath, start])

  return <g>{curD && <path d={curD} fill="currentColor" />}</g>
}
