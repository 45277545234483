import { IonRouterLink } from '@ionic/react'
import { PageTemplate } from '../components/templates/PageTemplate'

export const NotFound = () => {
  return (
    <PageTemplate title="Not Found">
      <div className="container mx-auto">
        <div className="p-4">
          <article>
            <h1 className="text-6xl">404</h1>
            <h2 className="text-2xl">Page not found</h2>
          </article>
          <div className="mt-4">
            <IonRouterLink routerLink="/app">Back to App</IonRouterLink>
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

export default NotFound
