"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageProxyBase = exports.validatePostMessage = void 0;
const nanoid_1 = require("nanoid");
const getMessageId = () => (0, nanoid_1.nanoid)();
const validatePostMessage = (data) => {
    const val = data;
    const namespaceList = ["Auth", "Common"];
    return namespaceList.includes(val.namespace);
};
exports.validatePostMessage = validatePostMessage;
class MessageProxyBase {
    constructor() { }
    addEventListener(namespace, listener) {
        window.addEventListener("message", (event) => {
            const data = event.data || {};
            if (event.source !== window ||
                namespace !== data.namespace ||
                !data.messageId ||
                !(0, exports.validatePostMessage)(data)) {
                return;
            }
            return listener(event, data);
        }, false);
    }
    postMessage(param, timeout = 5000) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const messageId = getMessageId();
                const clearId = window.setTimeout(() => {
                    window.removeEventListener("message", eventHandler);
                    reject();
                }, timeout);
                const eventHandler = (event) => {
                    const data = event.data;
                    if (event.source !== window ||
                        (data === null || data === void 0 ? void 0 : data.messageId) !== messageId ||
                        (data === null || data === void 0 ? void 0 : data._type) !== "response") {
                        return;
                    }
                    window.clearTimeout(clearId);
                    window.removeEventListener("message", eventHandler);
                    if (data.error) {
                        return reject(data.error);
                    }
                    resolve(data.data);
                };
                window.addEventListener("message", eventHandler);
                const message = Object.assign(Object.assign({}, param), { _type: "request", messageId });
                window.postMessage(message, "*");
            });
        });
    }
    returnPostMessage(event, messageId, data, error) {
        const message = {
            _type: "response",
            messageId: messageId,
            data: data,
            error: error,
        };
        window.postMessage(message, event.origin);
    }
}
exports.MessageProxyBase = MessageProxyBase;
