import { getPlatforms } from '@ionic/react'
import { useMemo } from 'react'

export type Platform = 'native' | 'web'

export const usePlatform = (platform: Platform | Platform[]) => {
  const _platforms = getPlatforms()

  return useMemo(() => {
    const platforms = Array.isArray(platform) ? platform : [platform]
    return platforms.reduce((prev, item) => {
      if (prev) {
        return true
      }
      if (item === 'native') {
        return _platforms.some((p) => p === 'capacitor')
      } else if (item === 'web') {
        return _platforms.some((p) => p === 'desktop' || p === 'mobileweb')
      }
      return false
    }, false)
  }, [platform, _platforms])
}

export const usePlatformIOSSafari = () => {
  const _platforms = getPlatforms()

  return _platforms.indexOf('ios') >= 0 && _platforms.indexOf('mobileweb') >= 0
}
