import { selector } from 'recoil'
import { GenerateTaskQueueDocument, GetTaskDocument } from '../generated'
import { handleError } from '../utils/handle-error'
import { authedClientSelector } from './client'

export const taskActions = selector({
  key: 'TaskState/taskActions',
  get: ({ get, getCallback }) => {
    const client = get(authedClientSelector)

    const getNextTask = getCallback(() => async () => {
      if (!client) return
      const { data, error: taskError } = await client.query(GetTaskDocument, {}).toPromise()
      if (taskError) {
        throw handleError(taskError)
      }
      const task = data?.task
      if (!task) {
        return undefined
      }
      const item = task.task_queue
        .map((qid) => task.items.find((t) => t.id === qid))
        .filter((o): o is NonNullable<typeof o> => Boolean(o))[0]

      return item
    })

    const generateTaskQueueMutation = getCallback(() => async (ids: string[], version: number) => {
      if (!client) return
      const { error } = await client
        .mutation(
          GenerateTaskQueueDocument,
          {
            items: ids,
            version,
          },
          { additionalTypenames: ['Task'] }
        )
        .toPromise()
      if (error) {
        throw handleError(error)
      }
    })

    return {
      getNextTask,
      generateTaskQueueMutation,
    }
  },
})
