import { App } from '@capacitor/app'
import { isPlatform, useIonToast } from '@ionic/react'
import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch'
import { differenceInSeconds } from 'date-fns'
import { useEffect, useRef } from 'react'
import { useIdle } from 'react-use'
import { useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil'
import { requestState } from '../recoil/request'
import { tickSelector } from '../recoil/timer'
import { syncTimerActions, timerActions, timerBell } from '../recoil/timerActions'
import { logger } from '../utils/logger'

const isNative = isPlatform('capacitor')

export const useTimer = () => {
  const { startTimer, stopTimer, continueTimer } = useRecoilValue(timerActions)

  return {
    startTimer,
    stopTimer,
    continueTimer,
  }
}

export const useSyncTimerActions = () => {
  const actions = useRecoilValue(syncTimerActions)

  return {
    syncTimers: actions?.syncTimers,
  }
}

type RequestAction =
  | {
      type: 'error'
    }
  | {
      type: 'reset'
    }

const KEY = 'timer'
export const useTimerAutomation = () => {
  const request = useRecoilValue(requestState(KEY))
  const requestReducer = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (action: RequestAction) => {
        switch (action.type) {
          case 'error':
            set(requestState(KEY), (prev) => ({
              errorCount: prev.errorCount + 1,
              prevTimestamp: Date.now(),
            }))
            break
          case 'reset': {
            const prev = get(requestState(KEY))
            if (prev.errorCount === 0) {
              break
            }
            set(requestState(KEY), {
              errorCount: 0,
              prevTimestamp: 0,
            })
            break
          }
          default:
            break
        }
      },
    []
  )
  const tick = useRecoilValue(tickSelector('seconds'))
  const minutesTick = useRecoilValue(tickSelector('minutes'))
  const { ringBell } = useRecoilValue(timerBell({ audioType: 'bell', time_basis: 'end_time' }))
  const { ringBell: ringTick } = useRecoilValue(timerBell({ audioType: 'tick', time_basis: 'start_time' }))
  const { continueTimer, checkTimer } = useRecoilValue(timerActions)
  const syncActions = useRecoilValue(syncTimerActions)
  const [present] = useIonToast()

  const reqRef = useRef(false)

  useEffect(() => {
    if (isNative) {
      BackgroundFetch.configure(
        { minimumFetchInterval: 15, stopOnTerminate: true },
        async (taskId) => {
          console.log('[BackgroundFetch] EVENT:', taskId)
          await syncActions?.syncTimers()
          await checkTimer()
          await continueTimer(Date.now())
          console.log('[BackgroundFetch] work complete', taskId)
          BackgroundFetch.finish(taskId)
        },
        (taskId) => {
          // The OS has signalled that your remaining background-time has expired.
          // You must immediately complete your work and signal #finish.
          console.log('[BackgroundFetch] TIMEOUT:', taskId)
          // [REQUIRED] Signal to the OS that your work is complete.
          BackgroundFetch.finish(taskId)
        }
      )
        .then((bfStatus) => {
          // Checking BackgroundFetch status:
          if (bfStatus !== BackgroundFetch.STATUS_AVAILABLE) {
            // Uh-oh:  we have a problem:
            if (bfStatus === BackgroundFetch.STATUS_DENIED) {
              console.warn('The user explicitly disabled background behavior for this app or for the whole system.')
            } else if (bfStatus === BackgroundFetch.STATUS_RESTRICTED) {
              console.warn('Background updates are unavailable and the user cannot enable them again.')
            }
          }
        })
        .catch((err) => console.debug(err))
    }
  }, [checkTimer, continueTimer, syncActions])

  useEffect(() => {
    if (reqRef.current) {
      return
    }
    if (request.errorCount > 0) {
      const now = Date.now()
      const diffSec = differenceInSeconds(now, request.prevTimestamp)
      const BASE = 4
      const threshold = Math.pow(BASE, request.errorCount)
      if (diffSec < threshold) {
        return
      }
    }
    reqRef.current = true
    continueTimer(tick)
      .then(() => requestReducer({ type: 'reset' }))
      .catch((err) => {
        requestReducer({ type: 'error' })
        if (err instanceof Error) {
          present({
            message: err.message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          })
        }
      })
      .finally(() => {
        reqRef.current = false
      })
    // [..., request] exclude request state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueTimer, present, requestReducer, tick])

  useEffect(() => {
    const sec = 40 * Math.random()
    window.setTimeout(() => {
      checkTimer().then(() => requestReducer({ type: 'reset' }))
    }, sec * 1000)
    logger.debug(`Check timer after ${sec}sec`)
  }, [checkTimer, minutesTick, requestReducer])

  useEffect(() => {
    ringBell?.(tick)
    ringTick?.(tick)
  }, [ringBell, ringTick, tick])
}

const IDLE_REFRESH_TIME = 3 * 60e3 // 60e3 = 1min

export const useTimerSyncAutomation = () => {
  const isIdle = useIdle(IDLE_REFRESH_TIME)
  const syncActions = useRecoilValue(syncTimerActions)

  useEffect(() => {
    if (!isIdle) {
      syncActions?.syncTimers()
    }
  }, [isIdle, syncActions])

  useEffect(() => {
    if (!isNative) {
      return
    }

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        syncActions?.syncTimers()
      }
    })
  }, [syncActions])
}
