import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useMutation, useQuery } from 'urql'
import { GetMeDocument, UpdateUserMutationDocument } from '../generated'
import { authState } from '../recoil/auth'
import { getTimeZone } from '../utils/date'

export const useMeTimezone = () => {
  const auth = useRecoilValue(authState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, reexecuteQuery] = useQuery({ query: GetMeDocument, pause: !auth })
  const [, updateUserMutation] = useMutation(UpdateUserMutationDocument)
  const me = result.data?.me

  useEffect(() => {
    if (!me) {
      return
    }

    window.dataLayer.push({
      user_id: me.user_id,
    })

    if (me.timezone === null) {
      const timezone = getTimeZone()
      updateUserMutation({ input: { timezone } })
    }
  }, [me, updateUserMutation])
}
