import { IonActionSheet, useIonToast } from '@ionic/react'
import { useCallback, useEffect, useReducer } from 'react'
import { useRecoilValue } from 'recoil'
import { timerTickInfoSelector } from '../../../recoil/timer'
import { timerActions } from '../../../recoil/timerActions'

export type ContinueTimerActionSheetProps = {
  //
}

type Action =
  | { type: 'open' }
  | { type: 'reset' }
  | {
      type: 'alreadyDismissed'
    }

type State = { open: boolean; alreadyDismissed: boolean }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'open': {
      return {
        open: true,
        alreadyDismissed: false,
      }
    }
    case 'alreadyDismissed': {
      return {
        open: false,
        alreadyDismissed: true,
      }
    }
    case 'reset': {
      return {
        open: false,
        alreadyDismissed: false,
      }
    }
    default:
      throw new Error(`Unhandled action type - ${JSON.stringify(action)}`)
  }
}

// eslint-disable-next-line no-empty-pattern
export const ContinueTimerActionSheet = ({}: ContinueTimerActionSheetProps) => {
  const [present] = useIonToast()
  const info = useRecoilValue(timerTickInfoSelector)
  const { startTimer, stopTimer } = useRecoilValue(timerActions)
  const [state, dispatch] = useReducer(reducer, { open: false, alreadyDismissed: false })

  const isWorking = Boolean(info?.info === 'working')

  useEffect(() => {
    if (!info || state.open || state.alreadyDismissed || info.is_finished) {
      return
    }
    if (info.info === 'completed' && info.interval === 'rest') {
      dispatch({ type: 'open' })
    }
  }, [info, state, stopTimer])

  useEffect(() => {
    if (!info || state.alreadyDismissed || info.is_finished) {
      return
    }
    if (info.info === 'completed' && info.interval === 'rest' && info.nextSeconds < 0) {
      dispatch({ type: 'alreadyDismissed' })
      stopTimer()
    }
  }, [info, state.alreadyDismissed, stopTimer])

  useEffect(() => {
    if (isWorking) {
      dispatch({ type: 'reset' })
    }
  }, [isWorking])

  const handleCancel = useCallback(async () => {
    try {
      dispatch({ type: 'alreadyDismissed' })
      await stopTimer()
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, stopTimer])
  const handleContinue = useCallback(async () => {
    try {
      await startTimer(true)
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, startTimer])

  return (
    <IonActionSheet
      id="helloworld"
      isOpen={state.open}
      header="Would you like to continue?"
      backdropDismiss={false}
      buttons={[
        {
          text: 'Continue',
          handler: handleContinue,
        },
        {
          text: 'Cancel',
          role: 'destructive',
          handler: handleCancel,
        },
      ]}
    ></IonActionSheet>
  )
}
