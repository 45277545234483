import { App, URLOpenListenerEvent } from '@capacitor/app'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AppUrlListener = () => {
  const history = useHistory()
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://unifocus.link/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split(/\.(link|net)/).pop()
      if (slug) {
        history.replace(slug)
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [history])

  return <></>
}

export default AppUrlListener
