import { IonContent, IonRouterLink } from '@ionic/react'
import { Redirect } from 'react-router'
import { MainTemplate } from '../components/templates/MainTemplate'
import { usePlatform } from '../hooks/usePlatform'

export const TopPage = () => {
  const isNative = usePlatform('native')

  if (isNative) {
    return <Redirect to="/app" />
  }
  return (
    <MainTemplate>
      <IonContent fullscreen>
        <div className="container mx-auto">
          <div className="flex flex-col justify-evenly items-center py-4 h-96 sm:flex-row md:py-16">
            <div>
              <h1 className="font-logo text-4xl">UniFocus</h1>
              <p className="mt-3 text-slate-600">
                <span className="underline decoration-sky-500 decoration-2 underline-offset-2">Time</span> &{' '}
                <span className="underline decoration-indigo-500 decoration-2 underline-offset-2">Focus</span>{' '}
                management app
                <br />
                using Pomodoro technique.
              </p>
            </div>
            <div className="text-2xl">
              <IonRouterLink routerLink="/app">
                <h3>Start Now!</h3>
              </IonRouterLink>
            </div>
          </div>
        </div>
      </IonContent>
    </MainTemplate>
  )
}

export default TopPage
