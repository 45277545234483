import clsx from 'clsx'
import { SyntheticEvent } from 'react'
import { COLORS } from '../../../consts/color'

export type TaskColorRadioProps = {
  selected: string
  onChange: (value: { colorName: string; colorCode: string }) => void
}

export const TaskColorRadio = ({ selected, onChange }: TaskColorRadioProps) => {
  const onClickColor = (colorName: string) => (e: SyntheticEvent<HTMLButtonElement>) => {
    const style = window.getComputedStyle(e.currentTarget)
    onChange({ colorName, colorCode: style.backgroundColor })
  }
  return (
    <div className="flex flex-wrap gap-2 px-1">
      {COLORS.map(({ color, css }) => (
        <button
          key={color}
          title={color}
          className={clsx('w-5 h-5 rounded ring-offset-1', css, color === selected && 'ring-2 ring-red-500')}
          onClick={color !== selected ? onClickColor(color) : undefined}
        ></button>
      ))}
      <button
        title="transparent"
        className={clsx(
          'relative w-5 h-5 text-red-500 bg-white rounded ring-offset-1',
          'transparent' === selected && 'ring-2 ring-red-400'
        )}
        onClick={() => onChange({ colorName: 'transparent', colorCode: 'transparent' })}
      >
        <svg viewBox="0 0 10 10">
          <path d="M1 1 L9 9" strokeWidth={1} stroke="currentColor" />
          <path d="M9 1 L1 9" strokeWidth={1} stroke="currentColor" />
        </svg>
      </button>
    </div>
  )
}
