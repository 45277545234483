import clsx from 'clsx'
import { secondsToMinutes } from 'date-fns'
import { Suspense, useState } from 'react'
import { timerConfigSelector, timerTickInfoSelector } from '../../../recoil/timer'
import { timerFormat } from '../../../utils/format'
import { padLeftStrng } from '../../../utils/util'
import { RecoilResolver } from '../utils/RecoilResolver'

export const TimerInfo = () => {
  const [isExpand, setIsExpand] = useState(false)
  return (
    <Suspense fallback={<></>}>
      <RecoilResolver state={timerTickInfoSelector}>
        {(info) => {
          if (!info || info.is_finished) return null
          const nextSecs = info?.nextSeconds || 0
          const minutes = secondsToMinutes(nextSecs)
          const seconds = nextSecs - minutes * 60

          return (
            <div>
              <button
                className={clsx('p-2 my-2 min-w-[10rem] text-black bg-white dark:bg-slate-100 rounded shadow')}
                onClick={() => setIsExpand((prev) => !prev)}
              >
                <RecoilResolver state={timerConfigSelector}>
                  {(config) => (
                    <div className="flex flex-col justify-center items-center">
                      {config && isExpand && (
                        <>
                          <p className="text-sm">
                            {timerFormat(info.timer.start_time)} ~ {timerFormat(info.timer.end_time)}
                          </p>
                          <p className="text-sm">
                            Reps: {info.timer.reps}/{config.reps}
                          </p>
                        </>
                      )}
                      <p className="text-lg">
                        {{ working: '🚀', interval: '⏳', rest: '🏖️' }[info.interval]}{' '}
                        {`${padLeftStrng(minutes)}:${padLeftStrng(seconds)}`}
                      </p>
                    </div>
                  )}
                </RecoilResolver>
              </button>
            </div>
          )
        }}
      </RecoilResolver>
    </Suspense>
  )
}
