import { selector } from 'recoil'
import { DeleteUserMutationDocument } from '../generated'
import { handleError } from '../utils/handle-error'
import { authState } from './auth'
import { authedClientSelector } from './client'

export const userActions = selector({
  key: 'UserState',
  get: ({ get, getCallback }) => {
    const client = get(authedClientSelector)
    const deleteUser = getCallback(({ snapshot }) => async () => {
      if (!client) {
        return
      }
      const session = await snapshot.getPromise(authState)
      const accessToken = session?.getAccessToken()
      if (!accessToken) {
        return
      }
      const { error } = await client.mutation(DeleteUserMutationDocument, {}).toPromise()

      if (error) {
        throw handleError(error)
      }
    })

    return {
      deleteUser,
    }
  },
})
