import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css' // default theme
import { IonApp, IonLoading, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Provider as UrqlProvider } from 'urql'
import AppUrlListener from './components/atoms/AppUrlListener'
import { DebugObserver } from './components/atoms/debug/DebugObserver'
import { AppModule } from './components/organisms/AppModule'
import { RecoilResolver } from './components/organisms/utils/RecoilResolver'
import './index.css'
import AppPage from './pages/AppPage'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import PolicyPage from './pages/PolicyPage'
import TopPage from './pages/TopPage'
import { urqlClient } from './recoil/client'
/* Theme variables */
import './theme/variables.css'

setupIonicReact()

const App: React.FC = () => (
  <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
    <RecoilRoot>
      <AmplifyProvider>
        <Authenticator.Provider>
          <RecoilResolver state={urqlClient}>
            {(client) => (
              <UrqlProvider value={client}>
                <Suspense fallback={<IonLoading isOpen message={'Please wait...'} />}>
                  <IonApp>
                    <IonReactRouter>
                      <AppUrlListener />
                      <IonRouterOutlet>
                        <Switch>
                          <Route path="/app" render={(props) => <AppPage {...props} />} />
                          <Route path="/policy" render={(props) => <PolicyPage {...props} />} />
                          <Route exact path="/login" component={Login} />
                          <Route exact path="/" component={TopPage} />
                          <Route exact path={['/home']}>
                            <Redirect to="/app" />
                          </Route>
                          <Route component={NotFound} />
                        </Switch>
                      </IonRouterOutlet>
                      {/* Global component */}
                      <ErrorBoundary fallback={<h1>Global function error occured.</h1>}>
                        <Suspense fallback={<></>}>
                          <AppModule />
                          <DebugObserver ignoreList={['TimerState/tickState']} />
                        </Suspense>
                      </ErrorBoundary>
                    </IonReactRouter>
                  </IonApp>
                </Suspense>
              </UrqlProvider>
            )}
          </RecoilResolver>
        </Authenticator.Provider>
      </AmplifyProvider>
    </RecoilRoot>
  </ErrorBoundary>
)

export default App
