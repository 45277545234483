import { FC, ReactNode } from 'react'
import clockUtils from '../../../../utils/clock-utils'
import type { TimerViewport } from '../../../../viewport'

const TICKS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
export interface NormalFrameProps {
  fill: string
  radius: number
  viewport: TimerViewport
  frameColor: string
  children?: ReactNode
}

const NormalFrame: FC<NormalFrameProps> = ({ radius, viewport, frameColor, fill, children }) => {
  return (
    <g>
      <circle cx={viewport.center.x} cy={viewport.center.y} r={radius} fill={fill} />
      <defs>
        <filter id="frame-shadow" filterUnits="userSpaceOnUse">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="0.5" dy="0.5" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {children}
      {TICKS.map((t, idx) => {
        const innerRadius = radius * 0.9
        const degree = t * 30
        const { x, y } = clockUtils.getXY(degree)
        const moveToXCenter = (val: number) => {
          return (val + viewport.center.x).toFixed(5)
        }
        const moveToYCenter = (val: number) => {
          return (val + viewport.center.y).toFixed(5)
        }
        const x1 = moveToXCenter(x * radius)
        const x2 = moveToXCenter(x * innerRadius)
        const y1 = moveToYCenter(y * radius)
        const y2 = moveToYCenter(y * innerRadius)
        return (
          <line
            key={idx}
            x1={x1}
            x2={x2}
            y1={y1}
            y2={y2}
            strokeWidth={clockUtils.getStroke(viewport.width, viewport.height, 120)}
            stroke={frameColor}
          />
        )
      })}
      <circle
        cx={viewport.center.x}
        cy={viewport.center.y}
        r={radius}
        fill="none"
        stroke={frameColor}
        strokeWidth={clockUtils.getStroke(viewport.width, viewport.height, 120) * 0.5}
        style={{ filter: 'url(#frame-shadow)' }}
      />
    </g>
  )
}

export default NormalFrame
