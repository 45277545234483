import { useRecoilValue } from 'recoil'
import { audioActions } from '../recoil/audio'

export const useAudio = (soundType: Parameters<typeof audioActions>[0]) => {
  const { audioProps } = useRecoilValue(audioActions(soundType))

  return {
    props: audioProps,
  }
}
