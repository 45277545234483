import { RecoilValueReadOnly } from 'recoil'
import type { TimerViewport } from '../../viewport'
import { HourHand, MinuteHand, SecondHand } from '../atoms/normal/index'
import { RecoilResolver } from '../organisms/utils/RecoilResolver'

export interface ClockHandsProps {
  tickState: (param: 'seconds' | 'minutes' | 'hours') => RecoilValueReadOnly<number>
  viewport: TimerViewport
  color: string
  radius: number
}

export default function ClockHands({ tickState, ...rest }: ClockHandsProps) {
  return (
    <g>
      <RecoilResolver state={tickState('seconds')}>
        {(tick) => (
          <>
            <SecondHand {...rest} time={tick} />
            <MinuteHand {...rest} time={tick} />
          </>
        )}
      </RecoilResolver>
      <RecoilResolver state={tickState('minutes')}>{(tick) => <HourHand {...rest} time={tick} />}</RecoilResolver>
    </g>
  )
}
