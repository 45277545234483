import { Storage } from '@ionic/storage'
import { DatabaseName } from '.'

export const TimerHistoryStoreKey = 'timer_history'

export const historyStorage = new Storage({
  name: DatabaseName,
  storeName: TimerHistoryStoreKey,
})
historyStorage.create()
