import { IonIcon } from '@ionic/react'
import { balloonOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { HOME_FLOAT_ACTION_ID } from '../../../consts/dom'

export type PictureInPictureActionProps = {
  active: boolean
  onClick: () => void
}

export const PictureInPictureAction = ({ active, onClick }: PictureInPictureActionProps) => {
  const [ref, setRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const tryMountFactory = () => {
      let count = 0
      const tryMount = () => {
        count += 1
        const dom = document.getElementById(HOME_FLOAT_ACTION_ID)
        if (dom) {
          setRef(dom)
        } else {
          if (count < 5) {
            window.setTimeout(tryMount, 500)
          }
        }
      }
      return tryMount
    }
    const tryMount = tryMountFactory()
    tryMount()
  }, [])

  if (!ref) {
    return <></>
  }

  return createPortal(
    <button
      onClick={onClick}
      className="grid place-content-center py-1.5 px-4 text-slate-400 bg-white rounded-full outline-2 outline outline-offset-2"
    >
      <IonIcon icon={balloonOutline} />
    </button>,
    ref
  )
}
