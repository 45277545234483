import { AtomEffect } from 'recoil'
import { storage } from '../storage/default'

export const storageEffect =
  <T, D>(key: string, mapper?: (arg: D) => T | null): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    setSelf(
      storage
        .get(key)
        .then((savedValue) => {
          if (savedValue === null) {
            return null
          }
          const val = mapper ? mapper(savedValue) : savedValue
          if (val === null) {
            return null
          }
          return val
        })
        .catch((err) => {
          console.error('Error at effectHelper', err)
          return null
        })
    )

    // Subscribe to state changes and persist them to localForage
    onSet((newValue, _, isReset) => {
      isReset ? storage.remove(key) : storage.set(key, newValue)
    })
  }
