import { IonContent, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import clsx from 'clsx'
import { alarmOutline, listOutline, ribbonOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { MainTemplate } from '../components/templates/MainTemplate'
import { usePlatformIOSSafari } from '../hooks/usePlatform'
import { Achivement } from './app/Achivement'
import { Home } from './app/Home'
import { Task } from './app/Task'

const TitleMap = {
  achivement: 'Achivement',
  home: undefined,
  task: 'Task',
}

const AppPage: React.FC<RouteComponentProps> = ({ match }) => {
  const isIosSafari = usePlatformIOSSafari()
  const [tab, setTab] = useState<keyof typeof TitleMap>('home')
  return (
    <IonContent>
      <IonTabs>
        <IonRouterOutlet>
          <MainTemplate title={TitleMap[tab]}>
            <Route exact path={`${match.url}/achivement`} component={Achivement} />
            <Route exact path={`${match.url}/home`} component={Home} />
            <Route exact path={`${match.url}/task`} component={Task} />
          </MainTemplate>
          <Route exact path={match.url}>
            <Redirect to={`${match.url}/home`} />
          </Route>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          onIonTabsDidChange={(event) => setTab(event.detail.tab as typeof tab)}
          className={clsx(isIosSafari && 'pb-20')}
        >
          <IonTabButton tab="achivement" href={`${match.url}/achivement`}>
            <IonIcon icon={ribbonOutline} />
            <IonLabel>Achivement</IonLabel>
          </IonTabButton>
          <IonTabButton tab="home" href={`${match.url}/home`}>
            <IonIcon icon={alarmOutline} />
            <IonLabel>Timer</IonLabel>
          </IonTabButton>
          <IonTabButton tab="task" href={`${match.url}/task`}>
            <IonIcon icon={listOutline} />
            <IonLabel>Task</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonContent>
  )
}

export default AppPage
