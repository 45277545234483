import { Storage } from '@ionic/storage'
import { DatabaseName } from '.'

export const storage = new Storage({
  name: DatabaseName,
})
storage.create()

export enum StorageEnum {
  TIMER = 'TIMER',
  TIMER_CONFIG = 'TIMER_CONFIG',
  CONFIG_THEME = 'CONFIG_THEME',
}
