import type { CombinedError } from '@urql/core'
import { NO_USER_ERROR } from './error-code'
import { NetworkError, NoUserError, UnexpectedError } from './errors'
import { logger } from './logger'

export const handleError = (err: CombinedError) => {
  const { networkError, graphQLErrors } = err
  if (networkError) {
    return new NetworkError(networkError)
  }

  const noUser = graphQLErrors.find((e) => e.extensions.code === NO_USER_ERROR)
  if (noUser) {
    return new NoUserError(noUser)
  }

  logger.error(err)
  return new UnexpectedError()
}
