import { hasErrorCode } from '@hiit/shared/error'
import { atomFamily, selector, selectorFamily, SerializableParam } from 'recoil'
import { LocalID, SyncID } from '../entity/ID'
import { ErrorCode } from '../error/error-code'
import {
  CheckTimerMutationDocument,
  CreateTimerMutationDocument,
  FinishTimerMutationDocument,
  GetTimerAndConfigDocument,
  MyTimerConfigMutationDocument,
} from '../generated'
import { handleError } from '../utils/handle-error'
import { logger } from '../utils/logger'
import { getTimeByISO, toISOFromTimestamp } from '../utils/timer'
import { audioActions } from './audio'
import { authedClientSelector } from './client'
import { taskActions } from './task'
import { TimerConfig, timerConfigSelector, timerConfigState, timerState } from './timer'

const ONE_MINUTES_MS = 60 * 1000
const INITIAL_REPS = 1

export const localTimerActions = selector({
  key: 'TimerActions/localTimerActions',
  get: ({ getCallback }) => {
    const startTimer = getCallback(({ snapshot, set }) => async (isContinue?: boolean) => {
      const config = await snapshot.getPromise(timerConfigSelector)
      if (!config) {
        throw new Error('Unexpected error: timer config not exists')
      }
      const timer = await snapshot.getPromise(timerState)
      let startTime = Date.now()
      if (timer && isContinue) {
        startTime = timer.end_time + config.rest_minutes * ONE_MINUTES_MS
      }
      set(timerState, {
        id: new LocalID(),
        start_time: startTime,
        end_time: startTime + config.focus_minutes * ONE_MINUTES_MS,
        reps: INITIAL_REPS,
        config_id: config.id,
        is_finished: false,
        is_valid: false,
      })
      if (startTime <= Date.now()) {
        const { play } = await snapshot.getPromise(audioActions('tick'))
        await play?.()
      }
    })

    const stopTimer = getCallback(({ set }) => async () => {
      set(timerState, (prev) => {
        if (!prev || !prev?.is_valid) {
          return null
        }
        return {
          ...prev,
          is_finished: true,
        }
      })
    })

    const continueTimer = getCallback(({ snapshot, set }) => async (tick: number) => {
      const config = await snapshot.getPromise(timerConfigSelector)
      const timer = await snapshot.getPromise(timerState)
      // end_timeを超えて、かつ10分を過ぎていなければtimerを継続する
      if (!timer || !config) {
        return
      }
      if (tick < timer.end_time || config.reps <= timer.reps) {
        return
      }

      if (timer.end_time + 10 * ONE_MINUTES_MS < tick) {
        await stopTimer()
        return
      }

      const isRepsOver = timer.reps >= config.reps
      const reps = isRepsOver ? INITIAL_REPS : timer.reps + 1
      const waitingMinutes = isRepsOver ? config.rest_minutes : config.interval_minutes
      const start_time = timer.end_time + waitingMinutes * ONE_MINUTES_MS
      const end_time = start_time + config.focus_minutes * ONE_MINUTES_MS
      set(timerState, {
        id: new LocalID(),
        start_time,
        end_time,
        reps,
        config_id: config.id,
        is_finished: false,
        is_valid: false,
      })
    })

    const checkTimer = getCallback(({ snapshot, set }) => async () => {
      const timer = await snapshot.getPromise(timerState)
      if (!timer || timer.is_valid) {
        return
      }

      const now = Date.now()
      const nowPassed = now - timer.start_time
      const halfPassed = (timer.end_time - timer.start_time) / 2
      if (nowPassed < halfPassed) {
        return
      }

      set(timerState, {
        ...timer,
        is_valid: true,
      })
    })

    const saveConfig = getCallback(({ set }) => async (config: TimerConfig) => {
      set(timerConfigState('local'), config)
    })

    return {
      startTimer,
      stopTimer,
      continueTimer,
      checkTimer,
      saveConfig,
    }
  },
})

export const syncTimerActions = selector({
  key: 'TimerActions/syncTimerActions',
  get: ({ get, getCallback }) => {
    const client = get(authedClientSelector)
    if (!client) {
      return undefined
    }
    const { getNextTask } = get(taskActions)

    const syncTimers = getCallback(({ set }) => async () => {
      const { data, error } = await client
        .query(GetTimerAndConfigDocument, {}, { requestPolicy: 'network-only' })
        .toPromise()

      if (error) {
        throw handleError(error)
      }

      if (data?.timerConfig) {
        const { id, interval_minutes, focus_minutes, reps, rest_minutes } = data.timerConfig
        set(timerConfigState('sync'), {
          id: new SyncID(id),
          interval_minutes,
          focus_minutes,
          reps,
          rest_minutes,
        })
      } else {
        logger.error({ msg: 'Unexpected state', data, error })
      }

      if (!data?.timer) {
        set(timerState, null)
      } else if (data.timer.config_id === data.timerConfig?.id) {
        const { id, start_time, end_time, reps, prev_id, config_id, is_finished, is_valid, task } = data.timer
        set(timerState, {
          id: new SyncID(id),
          start_time: getTimeByISO(start_time),
          end_time: getTimeByISO(end_time),
          reps,
          prev_id: prev_id ? new SyncID(prev_id) : undefined,
          config_id: new SyncID(config_id),
          is_finished: Boolean(is_finished),
          is_valid: Boolean(is_valid),
          task: task ?? undefined,
        })
      } else {
        logger.error({ msg: 'Unexpected state', data, error })
      }
    })

    const startTimer = getCallback(({ snapshot, set }) => async (isContinue?: boolean) => {
      const config = await snapshot.getPromise(timerConfigSelector)
      if (!config) {
        throw new Error('Unexpected error: timer config not exists')
      }
      const timer = await snapshot.getPromise(timerState)
      let startTime = Date.now()
      if (timer && isContinue) {
        startTime = timer.end_time + config.rest_minutes * ONE_MINUTES_MS
      }
      const nextTask = await getNextTask()
      const { data, error } = await client
        .mutation(
          CreateTimerMutationDocument,
          {
            input: {
              config_id: config.id.id,
              start_time: toISOFromTimestamp(startTime),
              end_time: toISOFromTimestamp(startTime + config.focus_minutes * ONE_MINUTES_MS),
              prev_id: timer?.id.id,
              reps: INITIAL_REPS,
              task_id: nextTask ? nextTask.id : undefined,
            },
          },
          { additionalTypenames: ['Task'] }
        )
        .toPromise()
      if (error) {
        if (hasErrorCode(error.graphQLErrors, [ErrorCode.CONFIG_ID_MISMATCH])) {
          await syncTimers()
        }
        throw handleError(error)
      }
      if (!data?.createTimer) {
        return
      }
      const { id, start_time, end_time, reps, config_id, prev_id, task } = data.createTimer
      set(timerState, {
        id: new SyncID(id),
        start_time: getTimeByISO(start_time),
        end_time: getTimeByISO(end_time),
        reps,
        config_id: new SyncID(config_id),
        prev_id: prev_id ? new SyncID(prev_id) : undefined,
        is_finished: false,
        is_valid: false,
        task: task ?? undefined,
      })
      if (startTime <= Date.now()) {
        const { play } = await snapshot.getPromise(audioActions('tick'))
        await play?.()
      }
    })

    const stopTimer = getCallback(({ set, snapshot }) => async () => {
      const timer = await snapshot.getPromise(timerState)
      if (!timer) {
        return undefined
      }
      const { data, error } = await client
        .mutation(FinishTimerMutationDocument, { input: timer.id.id }, { additionalTypenames: ['Task'] })
        .toPromise()

      if (error) {
        // Sync with latest timer states.
        await syncTimers()
        throw handleError(error)
      }
      if (!data) {
        return
      }

      set(timerState, (prev) => {
        if (!data.finishTimer || !prev) {
          return null
        }
        const now = Date.now()
        return {
          ...prev,
          end_time: now < prev.end_time ? now : prev.end_time,
          is_finished: true,
        }
      })
    })

    const continueTimer = getCallback(({ snapshot, set }) => async (tick: number) => {
      const config = await snapshot.getPromise(timerConfigSelector)
      const timer = await snapshot.getPromise(timerState)
      // end_timeを超えて、かつ10分を過ぎていなければtimerを継続する
      if (!timer || !config) {
        return
      }
      if (tick < timer.end_time || timer.is_finished || config.reps <= timer.reps) {
        return
      }
      if (timer.end_time + 10 * ONE_MINUTES_MS < tick) {
        await stopTimer()
        return
      }
      const nextTask = await getNextTask()
      const isRepsOver = timer.reps >= config.reps
      const reps = isRepsOver ? INITIAL_REPS : timer.reps + 1
      const waitingMinutes = isRepsOver ? config.rest_minutes : config.interval_minutes
      const start_time = timer.end_time + waitingMinutes * ONE_MINUTES_MS
      const end_time = start_time + config.focus_minutes * ONE_MINUTES_MS
      const { error, data } = await client
        .mutation(
          CreateTimerMutationDocument,
          {
            input: {
              config_id: config.id.id,
              start_time: toISOFromTimestamp(start_time),
              end_time: toISOFromTimestamp(end_time),
              prev_id: timer?.id.id,
              reps,
              task_id: nextTask ? nextTask.id : undefined,
            },
          },
          { additionalTypenames: ['Task'] }
        )
        .toPromise()
      if (error) {
        throw handleError(error)
      }
      if (!data?.createTimer) {
        throw new Error('createTimer not returned')
      }
      set(timerState, {
        id: new SyncID(data.createTimer.id),
        start_time: getTimeByISO(data.createTimer.start_time),
        end_time: getTimeByISO(data.createTimer.end_time),
        reps: data.createTimer.reps,
        config_id: new SyncID(data.createTimer.config_id),
        prev_id: data.createTimer.prev_id ? new SyncID(data.createTimer.prev_id) : undefined,
        is_finished: false,
        is_valid: false,
        task: data.createTimer.task ?? undefined,
      })
    })

    const checkTimer = getCallback(({ snapshot, set }) => async () => {
      const timer = await snapshot.getPromise(timerState)
      if (!timer || timer.is_valid) {
        return
      }
      const timerId = timer.id.id
      const now = Date.now()
      const nowPassed = now - timer.start_time
      const halfPassed = (timer.end_time - timer.start_time) / 2
      if (nowPassed < halfPassed) {
        return
      }
      const { data, error } = await client
        .mutation(CheckTimerMutationDocument, {
          input: timerId,
        })
        .toPromise()
      if (error) {
        if (hasErrorCode(error.graphQLErrors, [ErrorCode.TIMER_NOT_EXISTS])) {
          // Only if timer stopped, sync
          await syncTimers()
        }
        throw handleError(error)
      } else if (!data) {
        throw new Error('data not exists')
      }
      set(timerState, {
        id: new SyncID(data.checkTimer.id),
        start_time: getTimeByISO(data.checkTimer.start_time),
        end_time: getTimeByISO(data.checkTimer.end_time),
        reps: data.checkTimer.reps,
        config_id: new SyncID(data.checkTimer.config_id),
        prev_id: data.checkTimer.prev_id ? new SyncID(data.checkTimer.prev_id) : undefined,
        is_finished: Boolean(data.checkTimer.is_finished),
        is_valid: Boolean(data.checkTimer.is_valid),
        task: data.checkTimer.task ?? undefined,
      })
    })

    const saveConfig = getCallback(({ set }) => async (config: Omit<TimerConfig, 'id'>) => {
      const { data, error } = await client
        .mutation(MyTimerConfigMutationDocument, {
          input: {
            focus_minutes: config.focus_minutes,
            interval_minutes: config.interval_minutes,
            rest_minutes: config.rest_minutes,
            reps: config.reps,
          },
        })
        .toPromise()
      if (error) {
        // Sync with latest timer states.
        await syncTimers()
        throw handleError(error)
      } else if (!data) {
        throw new Error('data not exists')
      }
      if (!data?.createTimerConfig) {
        return
      }
      const { id, focus_minutes, interval_minutes, rest_minutes, reps } = data.createTimerConfig
      set(timerConfigState('sync'), {
        id: new SyncID(id),
        focus_minutes,
        interval_minutes,
        rest_minutes,
        reps,
      })
      set(timerState, null)
    })

    return {
      syncTimers,
      startTimer,
      stopTimer,
      continueTimer,
      checkTimer,
      saveConfig,
    }
  },
})

export const timerActions = selector({
  key: 'TimerActions/actions',
  get: ({ get }) => {
    const syncActions = get(syncTimerActions)

    if (syncActions) {
      return syncActions
    }

    return get(localTimerActions)
  },
})

export const timerBellState = atomFamily<'ready' | 'rang' | undefined, SerializableParam>({
  key: 'TimerState/timerBellState',
  default: undefined,
})

export const timerBell = selectorFamily({
  key: 'TimerState/timerBell',
  get:
    ({ audioType, time_basis }: { audioType: 'bell' | 'tick'; time_basis: 'start_time' | 'end_time' }) =>
    ({ get, getCallback }) => {
      const timer = get(timerState)
      const { ref, play } = get(audioActions(audioType))
      if (!timer || !ref) {
        return {
          bellState: undefined,
        }
      }
      const atomKey = [timer.id, audioType, time_basis]
      const bellState = get(timerBellState(atomKey))

      const ringBell = getCallback(({ set }) => async (tick: number) => {
        if (!timer[time_basis] || bellState === 'rang') {
          return
        }

        if (tick < timer[time_basis] && bellState === undefined) {
          set(timerBellState(atomKey), 'ready')
          return
        }

        if (bellState === 'ready' && timer[time_basis] <= tick) {
          await play()
          set(timerBellState(atomKey), 'rang')
          logger.debug('ring bell')
        }
      })

      return {
        bellState,
        ringBell,
      }
    },
})
