/**
 * range(3)
 * => [0, 1, 2]
 * @param num
 * @returns
 */
export const range = (num: number) => [...Array(num).keys()]

/**
 * padLeftString(2)
 * => 02
 * @param num
 * @returns
 */
export const padLeftStrng = (num: number) => String(num).padStart(2, '0')
