import { useCallback } from 'react'
import { storage } from '../storage/default'
import { historyStorage } from '../storage/history'
import { logger } from '../utils/logger'

export const useStorage = () => {
  const clearStorage = useCallback(async () => {
    try {
      await storage.clear()
      await historyStorage.clear()
    } catch (err) {
      logger.warn(err)
    }
  }, [])

  return {
    clearStorage,
  }
}
