import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { FC, ReactNode, Suspense, useCallback } from 'react'
import { ThemeSelector } from '../organisms/header/ThemeSelector'

export interface HomeTemplateProps {
  title: string
  defaultBackHref?: string
  children?: ReactNode
}

export const PageTemplate: FC<HomeTemplateProps> = ({ title, defaultBackHref = '/app', children }) => {
  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref={defaultBackHref} />
          </IonButtons>
          <IonItem slot="end" lines="none" color="none">
            <Suspense fallback={<></>}>
              <ThemeSelector />
            </Suspense>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <>{children}</>
      </IonContent>
    </IonPage>
  )
}
