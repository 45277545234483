class BaseError extends Error {
  constructor(err: Error) {
    super(err.message)
    this.stack = err.stack
    this.cause = err.cause

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BaseError.prototype)
  }
}

export class UnexpectedError extends Error {
  constructor(message = 'Unexpected error.') {
    super(message)
    this.name = 'UnexpectedError'

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnexpectedError.prototype)
  }
}

export class NetworkError extends BaseError {
  constructor(err: Error) {
    super(err)
    this.name = 'NetworkError'

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NetworkError.prototype)
  }
}

export class NoUserError extends BaseError {
  constructor(err: Error) {
    super(err)
    this.name = 'NoUserError'

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NoUserError.prototype)
  }
}
