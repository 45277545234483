import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  SelectCustomEvent,
  useIonToast,
} from '@ionic/react'
import clsx from 'clsx'
import { addOutline } from 'ionicons/icons'
import { useCallback, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import { TaskSortType } from '../../../generated'
import { useTask } from '../../../hooks/useTask'
import { useThemeBackground } from '../../../hooks/useTheme'
import { timerTickInfoSelector } from '../../../recoil/timer'
import { RecoilResolver } from '../utils/RecoilResolver'

export const TaskQueueAction = () => {
  const [toastPresent] = useIonToast()
  const { color } = useThemeBackground()
  const { task, queue, sortTaskQueue, generateTaskQueue } = useTask()
  const [headQueue, ...tailQueue] = queue
  const [modalOpen, setModalOpen] = useState(false)
  const [openTaskList, setOpenTaskList] = useState(false)
  const { user } = useAuthenticator((context) => [context.user])

  const ref = useRef(null)
  useOnClickOutside(ref, () => setOpenTaskList(false))

  const handleReorder = useCallback(
    async (event: CustomEvent<ItemReorderEventDetail>) => {
      // The `from` and `to` properties contain the index of the item
      // when the drag started and ended, respectively
      console.log('Dragged from index', event.detail.from, 'to', event.detail.to)
      const from = event.detail.from
      const to = event.detail.to

      const result = await sortTaskQueue({ from, to, sort_type: TaskSortType.Manual })

      if (result?.error) {
        toastPresent({
          message: result?.error?.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
        return
      }
      // Finish the reorder and position the item in the DOM based on
      // where the gesture ended. This method can also be called directly
      // by the reorder group
      event.detail.complete()
    },
    [sortTaskQueue, toastPresent]
  )

  const handleChangeSortType = useCallback(
    async (event: SelectCustomEvent<TaskSortType>) => {
      const value = event.detail.value
      const result = await sortTaskQueue({ sort_type: value })
      if (result?.error) {
        toastPresent({
          message: result?.error?.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    },
    [sortTaskQueue, toastPresent]
  )

  if (!user) {
    return null
  }

  return (
    <>
      <div className="flex flex-col gap-2 items-end">
        {headQueue ? (
          <button
            className={clsx(
              'w-12 h-12 rounded-sm rounded-bl-lg border-b border-l border-stone-300 border-solid shadow-md',
              headQueue && headQueue.color_code !== 'transparent' ? 'text-white' : 'text-black dark:text-white'
            )}
            style={{ backgroundColor: headQueue.color_code !== 'transparent' ? headQueue.color_code : color }}
            onClick={() => setModalOpen(true)}
          >
            <div className="grid place-content-center">
              <p className="overflow-hidden text-base font-semibold text-clip">{headQueue?.task_name[0]}</p>
            </div>
          </button>
        ) : (
          <button
            className={clsx(
              'grid place-content-center w-12 h-12 rounded-sm rounded-bl-lg border-b border-l border-stone-300 border-solid shadow-md'
            )}
            onClick={() => setModalOpen(true)}
          >
            <IonIcon icon={addOutline} size="large" />
          </button>
        )}
        <RecoilResolver state={timerTickInfoSelector}>
          {(tickInfo) => (
            <>
              {tailQueue.map((q, i) => (
                <button
                  key={`${q?.id}-${i}`}
                  className={clsx(
                    'w-1.5 h-10 rounded-sm border-y border-l border-stone-300 border-solid',
                    tickInfo && tickInfo.info !== 'completed' && 'outline-white outline'
                  )}
                  style={{ backgroundColor: q.color_code !== 'transparent' ? q.color_code : color }}
                  onClick={() => setModalOpen(true)}
                ></button>
              ))}
            </>
          )}
        </RecoilResolver>
      </div>
      <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Task Session</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setModalOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="min-h-full">
            <div className="flex justify-end items-center">
              <p className="mx-2">Item {queue.length}</p>
              <div className="mx-auto" />
              <IonLabel>Sort by</IonLabel>
              <IonSelect
                interface="popover"
                placeholder="Sorting"
                selectedText={task?.sort_type}
                onIonChange={handleChangeSortType}
              >
                {[TaskSortType.TopToBottom, TaskSortType.RoundRobin, TaskSortType.Manual].map((type) => (
                  <IonSelectOption key={type} value={type}>
                    {type}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorder}
              className="list-none divide-y dark:divide-gray-700"
            >
              {queue.map((q, idx) => (
                <div
                  key={`${q.id}-${idx}`}
                  style={{ backgroundColor: q.color_code }}
                  className="flex gap-2 items-center h-12"
                >
                  <IonReorder className="text-gray-700"></IonReorder>
                  <p
                    className={clsx(
                      'text-lg font-semibold',
                      q.color_code === 'transparent' ? 'text-black dark:text-white' : 'text-white'
                    )}
                  >
                    {q.task_name}
                  </p>
                </div>
              ))}
            </IonReorderGroup>
          </div>
          <div ref={ref} className="sticky inset-x-0 bottom-0 p-1 bg-white">
            {openTaskList ? (
              <div className="grid grid-cols-3 gap-2">
                {task?.items.map((task) => (
                  <button
                    key={task.id}
                    className={clsx(
                      'w-full h-28 rounded',
                      task.color_code === 'transparent' ? 'text-black dark:text-white' : 'text-white'
                    )}
                    style={{ backgroundColor: task.color_code }}
                    onClick={() => generateTaskQueue([task.id])}
                  >
                    {task.task_name}
                  </button>
                ))}
              </div>
            ) : (
              <IonButton expand="full" onClick={() => setOpenTaskList(true)}>
                ADD
              </IonButton>
            )}
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}
