import { FC } from 'react'
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil'

export interface DebugObserverProps {
  ignoreList?: string[]
}

export const DebugObserver: FC<DebugObserverProps> = ({ ignoreList = [] }) => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      if (!ignoreList.includes(node.key)) {
        console.debug(`%c${node.key}`, 'color: yellow; background: blue; padding: 0 5px;', snapshot.getLoadable(node))
      }
    }
  })

  return <></>
}
