import { atomFamily, selectorFamily } from 'recoil'
import { SoundMap } from '../consts/sound'
import { logger } from '../utils/logger'

const audioRef = atomFamily<HTMLAudioElement | null, keyof typeof SoundMap>({
  key: 'Audio/audioRef',
  default: null,
  dangerouslyAllowMutability: true,
})

export const audioActions = selectorFamily({
  key: 'Audio/audioActions',
  get:
    (soundType: keyof typeof SoundMap) =>
    ({ get, getCallback }) => {
      const ref = get(audioRef(soundType))

      const setRef = getCallback(({ set }) => (ref: HTMLAudioElement | null) => {
        set(audioRef(soundType), ref)
      })

      const audioProps = {
        controls: false,
        ref: setRef,
        src: SoundMap[soundType],
        preload: 'true',
      }

      if (!ref) {
        return {
          audioProps,
          ref,
        }
      }

      const play = getCallback(() => async () => {
        const el = ref
        if (!el) {
          logger.debug('no audio element: play')
          return
        }
        try {
          await el.play()
        } catch (err) {
          logger.warn(err)
        }
      })
      const loop = getCallback(() => async () => {
        const el = ref
        if (!el) {
          logger.debug('no audio element: loop')
          return
        }
        try {
          el.loop = true
          await el.play()
        } catch (err) {
          logger.warn(err)
        }
      })
      const stop = getCallback(() => async () => {
        const el = ref
        if (!el) {
          logger.debug('no audio element: stop')
          return
        }
        try {
          el.loop = false
          el.pause()
          el.currentTime = 0
        } catch (err) {
          logger.warn(err)
        }
      })

      return {
        audioProps,
        ref,
        play,
        loop,
        stop,
      }
    },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: { eviction: 'keep-all' },
})
