import { addDays, formatDistanceStrict, parseISO } from 'date-fns'
import { uniq } from 'lodash'
import { selectorFamily } from 'recoil'
import { SyncID } from '../entity/ID'
import { SearchTimerHistoryQueryDocument } from '../generated'
import { historyStorage } from '../storage/history'
import { getTimeByISO } from '../utils/timer'
import { authedClientSelector } from './client'
import { timerConfigSelector, timerMappter, TimerState } from './timer'

export const timerHistoryByDateState = selectorFamily({
  key: 'TimerHistory/timerHistoryByDateState',
  get:
    (isoDate: string) =>
    async ({ get, getCallback }) => {
      const dateTime = parseISO(isoDate).toISOString()
      const config = get(timerConfigSelector)
      const historyIdsKey = config ? `${config.id._type}:${isoDate}` : ''
      const historyIds: readonly string[] = (await historyStorage.get(historyIdsKey)) || []

      let history: TimerState[] = []
      if (config) {
        try {
          history = (
            await Promise.all(
              historyIds.map(async (id) => {
                const serialized = await historyStorage.get(`${config.id._type}:${id}`)
                if (!serialized) {
                  return undefined
                }
                return timerMappter(serialized)
              })
            )
          ).filter((t) => t?.is_finished) as TimerState[]
        } catch (err) {
          console.error(err)
        }
      }
      const count = history.length
      const passedTime = history.reduce((acc, cur) => acc + cur.end_time - cur.start_time, 0)

      const refresh = getCallback(({ refresh }) => () => {
        refresh(timerHistoryByDateState(isoDate))
      })

      const initialize = getCallback(({ snapshot }) => async () => {
        const { timerHistory } = await snapshot.getPromise(timerHistoryQuery(dateTime))
        await Promise.all(
          timerHistory.map((t) =>
            historyStorage.set(`sync:${t.id}`, {
              id: new SyncID(t.id),
              start_time: getTimeByISO(t.start_time),
              end_time: getTimeByISO(t.end_time),
              reps: t.reps,
              prev_id: t.prev_id ? new SyncID(t.prev_id) : undefined,
              config_id: new SyncID(t.config_id),
              is_finished: Boolean(t.is_finished),
              is_valid: true,
            })
          )
        )
        await historyStorage.set(historyIdsKey, uniq([...historyIds, ...timerHistory.map((t) => t.id)]))
        refresh()
      })

      return {
        historyIds,
        count,
        history,
        duration: formatDistanceStrict(0, passedTime, { unit: 'minute' }),
        refresh,
        initialize,
      }
    },
})

export const timerHistoryQuery = selectorFamily({
  key: 'TimerHistory/timerHistoryQuery',
  get:
    (from: string) =>
    async ({ get, getCallback }) => {
      const client = get(authedClientSelector)
      if (!client) {
        return {
          timerHistory: [],
        }
      }

      const { data } = await client
        .query(SearchTimerHistoryQueryDocument, { from, to: addDays(parseISO(from), 1).toISOString() })
        .toPromise()

      const timerHistory = data?.timerHistory || []

      const refresh = getCallback(({ refresh }) => async () => {
        refresh(timerHistoryQuery(from))
      })

      return {
        timestamp: Date.now(),
        refresh,
        timerHistory,
      }
    },
})
