import clsx from 'clsx'
import { FunctionComponent, ReactNode } from 'react'
import { useThemeBackground } from '../../hooks/useTheme'
import { tickSelector, timerTickInfoSelector } from '../../recoil/timer'
import type { TimerViewport } from '../../viewport'
import ClockFrame from '../molecules/ClockFrame'
import ClockHands from '../molecules/ClockHands'
import { TimerGauge } from './gauge/TimerGauge'
import { RecoilResolver } from './utils/RecoilResolver'

// tslint:disable: object-literal-sort-keys
export const DEFAULT_VIEWPORT = {
  top: 0,
  left: 0,
  right: 600,
  bottom: 600,
  center: {
    x: 300,
    y: 300,
  },
  width: 600,
  height: 600,
}

export interface TimerClockProps {
  className?: string
  viewport?: TimerViewport
  stroke?: number
  time?: Date
  children?: ReactNode
}

export default function TimerClock({ viewport = DEFAULT_VIEWPORT, className }: TimerClockProps) {
  return (
    <div className={className}>
      <ClockComponent viewport={viewport} />
    </div>
  )
}

interface ClockComponentProps {
  viewport: TimerViewport
  children?: ReactNode
}

const ClockComponent: FunctionComponent<ClockComponentProps> = ({ viewport, children }: ClockComponentProps) => {
  const { color } = useThemeBackground()
  const clockConfig = {
    fill: '#ffdd00',
    stroke: '#ff0000',
    frameColor: '#ffffff',
  }
  const radius = viewport.center.x - 10

  return (
    <svg
      id="svg-id"
      viewBox={`${viewport.top} ${viewport.left} ${viewport.width} ${viewport.height}`}
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <RecoilResolver state={timerTickInfoSelector}>
          {(tickInfo) => {
            let fillColor = 'transparent'
            let isWorking = false
            if (tickInfo && tickInfo.info !== 'completed') {
              const timer = tickInfo.timer
              isWorking = true
              fillColor = timer?.task && timer.task.color_name !== 'transparent' ? timer.task.color_code : color
            }
            return (
              <rect
                className={clsx('transition-all duration-0', isWorking && 'delay-1000')}
                fill={fillColor}
                x={viewport.left}
                width={viewport.width}
                y={viewport.top}
                height={viewport.height}
              />
            )
          }}
        </RecoilResolver>

        <ClockFrame viewport={viewport} radius={radius} frameColor={clockConfig.frameColor} fill={clockConfig.fill}>
          <TimerGauge viewport={viewport} radius={radius} />
        </ClockFrame>
        <ClockHands tickState={tickSelector} viewport={viewport} color={clockConfig.stroke} radius={radius} />
        {children}
      </g>
    </svg>
  )
}
