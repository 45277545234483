export const COLORS = [
  {
    color: 'red',
    css: 'bg-red-400',
    code: 'rgb(248 113 113)',
  },
  {
    color: 'orange',
    css: 'bg-orange-400',
    code: 'rgb(251 146 60)',
  },
  {
    color: 'amber',
    css: 'bg-amber-400',
    code: 'rgb(251 191 36)',
  },
  {
    color: 'yellow',
    css: 'bg-yellow-400',
    code: 'rgb(250 204 21)',
  },
  {
    color: 'lime',
    css: 'bg-lime-400',
    code: 'rgb(163 230 53)',
  },
  {
    color: 'green',
    css: 'bg-green-400',
    code: 'rgb(74 222 128)',
  },
  {
    color: 'emerald',
    css: 'bg-emerald-400',
    code: 'rgb(52 211 153)',
  },
  {
    color: 'teal',
    css: 'bg-teal-400',
    code: 'rgb(45 212 191)',
  },
  {
    color: 'cyan',
    css: 'bg-cyan-400',
    code: 'rgb(34 211 238)',
  },
  {
    color: 'sky',
    css: 'bg-sky-400',
    code: 'rgb(56 189 248)',
  },
  {
    color: 'blue',
    css: 'bg-blue-400',
    code: 'rgb(96 165 250)',
  },
  {
    color: 'indigo',
    css: 'bg-indigo-400',
    code: 'rgb(129 140 248)',
  },
  {
    color: 'violet',
    css: 'bg-violet-400',
    code: 'rgb(167 139 250)',
  },
  {
    color: 'purple',
    css: 'bg-purple-400',
    code: 'rgb(192 132 252)',
  },
  {
    color: 'fuchsia',
    css: 'bg-fuchsia-400',
    code: 'rgb(232 121 249)',
  },
  {
    color: 'pink',
    css: 'bg-pink-400',
    code: 'rgb(244 114 182)',
  },
  {
    color: 'slate',
    css: 'bg-slate-400',
    code: 'rgb(148 163 184)',
  },
  {
    color: 'stone',
    css: 'bg-stone-400',
    code: 'rgb(168 162 158)',
  },
]
