import { useAuthenticator } from '@aws-amplify/ui-react'
import { IonButton, IonMenuButton } from '@ionic/react'
import { CircleText } from '../../atoms/CircleText'

export type AuthHeaderItemProps = {
  menuId: string
}

export const AuthHeaderItem = ({ menuId }: AuthHeaderItemProps) => {
  const { user } = useAuthenticator((context) => [context.user])

  const isLogin = Boolean(user)
  return (
    <>
      {isLogin ? (
        <IonMenuButton menu={menuId}>
          <CircleText text={user.username} />
        </IonMenuButton>
      ) : (
        <IonButton color="primary" fill="clear" shape="round" routerLink="/login">
          Sign In
        </IonButton>
      )}
    </>
  )
}
