import { IonRouterOutlet } from '@ionic/react'
import _capitalize from 'lodash/capitalize'
import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { PageTemplate } from '../components/templates/PageTemplate'
import { CookiePolicy } from './CookiePolicy'
import NotFound from './NotFound'
import { PrivacyPolicy } from './PrivacyPolicy'

const PolicyPage: React.FC<RouteComponentProps> = ({ match, location }) => {
  const paths = location.pathname.split('/')
  const [, , path] = paths

  if (!path || path.split('-').length < 2) {
    return <NotFound />
  }

  const title = path.split('-')[0]

  return (
    <IonRouterOutlet>
      <PageTemplate title={`${_capitalize(title)} Policy`}>
        <Route exact path={`${match.url}/cookie-policy`} component={CookiePolicy} />
        <Route exact path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
      </PageTemplate>
    </IonRouterOutlet>
  )
}

export default PolicyPage
