import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { playOutline, settingsSharp, stopOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTimer } from '../../../hooks/useTimer'
import { timerState } from '../../../recoil/timer'
import SettingForm from '../form/TimerSettingForm'
import { RecoilResolver } from '../utils/RecoilResolver'

export const TimerActions = () => {
  const [present] = useIonToast()
  const { startTimer, stopTimer } = useTimer()
  const [modalOpen, setModalOpen] = useState(false)

  const handleStart = useCallback(async () => {
    try {
      await startTimer()
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, startTimer])

  const handleStop = useCallback(async () => {
    try {
      await stopTimer()
    } catch (err) {
      if (err instanceof Error) {
        present({
          message: err.message,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        })
      }
    }
  }, [present, stopTimer])

  return (
    <>
      <div className="flex">
        <RecoilResolver state={timerState}>
          {(timer) =>
            timer && !timer.is_finished ? (
              <IonButton color="tertiary" onClick={handleStop} title="Stop">
                <IonIcon slot="start" icon={stopOutline} />
                Stop
              </IonButton>
            ) : (
              <>
                <IonButton fill="clear" color="medium" onClick={() => setModalOpen(true)} title="Timer Setting">
                  <IonIcon icon={settingsSharp} slot="icon-only" />
                </IonButton>
                <IonButton color="success" onClick={handleStart} title="Play">
                  <IonIcon slot="start" icon={playOutline} />
                  Play
                </IonButton>
              </>
            )
          }
        </RecoilResolver>
      </div>
      <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Timer Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setModalOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <SettingForm didSubmit={() => setModalOpen(false)} />
        </IonContent>
      </IonModal>
    </>
  )
}
